<template>
  <div>
    <div
      class="my-2 p-3 sm:-mx-6 lg:-mx-8 md:-mx-8 bg-white overflow-x-auto z-0"
    >
      <div
        class="py-2 md:px-52 align-middle inline-block min-w-full sm:px-6 lg:px-8"
      >
        <div class=" border-b shadow border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 bg-gray-50 text-left text-xs table-fixed font-medium text-gray-500 uppercase tracking-wider"
                >
                  Rank
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 bg-gray-50 text-left text-xs table-fixed font-medium text-gray-500 uppercase tracking-wider"
                >
                  Player Name
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold':
                      getActiveCol === 'obj_armvelo_throw_median'
                  }"
                  v-if="category.obj_armvelo_throw_median"
                  scope="col"
                  class="px-3 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('obj_armvelo_throw_median')"
                >
                  Median Arm Velo
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold': getActiveCol === 'home_to_1'
                  }"
                  v-if="category.hometofirst && !isBaseball"
                  scope="col"
                  class="px-3 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('home_to_1')"
                >
                  Home to First
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold': getActiveCol === 'home_to_home'
                  }"
                  v-if="category.hometohome && !isBaseball"
                  scope="col"
                  class="px-3 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('home_to_home')"
                >
                  Home to Home
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold': getActiveCol === 'min_60'
                  }"
                  v-if="category.sixtytime && isBaseball"
                  scope="col"
                  class="px-3 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('min_60')"
                >
                  60 Yard Dash
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold': getActiveCol === 'min_pop_time'
                  }"
                  v-if="category.poptime"
                  scope="col"
                  class="px-3 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('min_pop_time')"
                >
                  Pop Time
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(player, index) in data"
                :key="player.cse_playerid"
                class="player bg-white border-b border-gray-200 sm:rounded-lgshaddow min-w-full divide-y divide-gray-200"
                :class="{
                  selectFullRow: getSelection.id === player.id
                }"
              >
                <td
                  class="px-6 py-4 bg-white whitespace-nowrap text-sm font-medium text-gray-900"
                  :class="{
                    'text-theme-40 bg-theme-1 shadow-md':
                      getSelection.id === player.id
                  }"
                >
                  {{ index + 1 }}
                </td>
                <td
                  :class="{
                    'text-theme-40 bg-theme-1': getSelection.id === player.id
                  }"
                  class="lg:px-6 pr-2 pl-1 py-4 bg-white z-49 whitespace-nowrap text-sm text-gray-500"
                >
                  <p
                    :class="{
                      'text-theme-40 bg-theme-1': getSelection.id === player.id
                    }"
                    class="text-md font-medium text-theme-1"
                  >
                    {{ player.name }} {{ player.lastName }}
                  </p>
                  <p class="text-sm text-gray-500">
                    {{ player.sport }} <br />
                    GPA: {{ player.gpa }} <br />
                    Graduation Year:
                    {{ player.gradyear }}
                  </p>
                </td>
                <td
                  v-if="category.obj_armvelo_throw_median"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <p v-if="player.obj_armvelo_throw_median > 0">
                    {{ player.obj_armvelo_throw_median }} mph
                  </p>
                </td>
                <td
                  v-if="category.hometofirst && !isBaseball"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <p v-if="player.hometoFirst > 0">
                    {{ player.hometoFirst }} sec
                  </p>
                </td>
                <td
                  v-if="category.hometohome && !isBaseball"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <p v-if="player.hometoHome > 0">
                    {{ player.hometoHome }} sec
                  </p>
                </td>
                <td
                  v-if="category.sixtytime && isBaseball"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <p v-if="player.sixtytime > 0">{{ player.sixtytime }} sec</p>
                </td>
                <td
                  v-if="category.poptime"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <p v-if="player.popTime > 0">{{ player.popTime }} sec</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  name: "LeaderBoard",
  data() {
    return {
      loading: false,
      filter: {
        cse: true,
        defensivesubjective: true,
        obj_armvelo_throw_median: true,
        hometofirst: true,
        hometohome: true,
        poptime: true,
        sixtytime: true
      },
      tooltipShow: false,
      isMqEvent: false,
      isBaseball: false
    };
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    category: {
      type: Object,
      required: true
    },
    position: {
      type: Object,
      required: true
    },

    showCse: Boolean,
    showDefensiveSub: Boolean,
    showOverhandVelo: Boolean,
    showHometoFirst: Boolean,
    showHometoHome: Boolean,
    showSixtyTime: Boolean,
    showPopTime: Boolean
  },
  computed: {
    getSelection() {
      return this.$store.getters["score/getSelected"];
    },
    getActiveCol() {
      return this.$store.getters["events/activeColName"];
    }
  },
  methods: {
    emit(data, object, index) {
      console.log(index);

      let value = parseFloat(data);
      if (["obj_footspeed_h11", "obj_poptime_throw2"].includes(object)) {
        value = 0;
      }
      this.$store.commit("score/updateSelection", {
        index: index.index,
        id: index.id,
        colName: object
      });

      this.$store.commit("score/updateScore", value);
    },
    highlightColumn(colName) {
      this.$emit("highlight-col", colName);
    },
    activeColClickHandler(colName) {
      this.$store.commit("events/updateColName", colName);
    },
    toggleTooltip: function() {
      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "right"
        });
      }
    },
    showTooltip(flag) {
      switch (flag) {
        case 1:
          document.getElementById("tooltip1").classList.remove("opacity-0");
          break;
      }
    },
    hideTooltip(flag) {
      switch (flag) {
        case 1:
          document.getElementById("tooltip1").classList.add("opacity-0");
          break;
      }
    }
  },
  watch: {
    category: {
      handler(newVal) {
        console.log(newVal);
      },
      deep: true
    },
    position: {
      handler(newVal) {
        console.log(newVal);
      },
      deep: true
    },
    showCse: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          cse: newVal
        };
      }
    },
    showDefensiveSub: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          defensivesubjective: newVal
        };
      }
    },
    showOverhandVelo: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          obj_armvelo_throw_median: newVal
        };
      }
    },
    showHometoFirst: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          hometofirst: newVal
        };
      }
    },
    showHometoHome: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          hometohome: newVal
        };
      }
    },
    showSixtyTime: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          min_60: newVal
        };
      }
    },
    showPopTime: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          poptime: newVal
        };
      }
    }
  },
  mounted() {
    this.isMqEvent = this.$route.path.match(/mq-events/);
    this.isBaseball = this.$route.path.match(/Baseball/);
    this.isBaseball = this.$route.path.match(/baseball/);
  }
};
</script>

<style lang="css" scoped>
.player {
  margin-bottom: 1rem;
}

.col-cse {
  background: red;
  margin-bottom: 1rem;
}

.selectedRow {
  color: #d9ab28;
  font-weight: bold;
}

.selectFullRow {
  background-color: #0b3253;
  box-shadow: 1px 3px #f3f3f3;
}
</style>
