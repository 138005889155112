<template>
  <div class="score sticky mt-27 w-52">
    <div
      class="slider"
      :style="{
        bottom: `${calculateScore}px`,
        filter: `${calculateScore ? 'grayscale(0)' : 'grayscale(0)'}`
      }"
    ></div>
    <img
      :style="{
        filter: `${calculateScore ? 'grayscale(0)' : 'grayscale(0)'}`
      }"
      src="@/assets/Slider9.png"
      alt="Score calculator board"
    />
  </div>
</template>

<script>
export default {
  name: "Score",
  props: {
    score: {
      type: Number,
      default: 0
    }
  },
  computed: {
    // TODO This is an issue
    calculateScore() {
      const score = this.$store.getters["score/getScore"];
      // const imageHeight = 500;
      // const value = (this.score / 100)  * imageHeight
      return score === 0 ? 0 : ((score - 20) / 3) * 25;
    }
  }
};
</script>

<style lang="css">
.score {
  position: relative;
}

.score img {
  width: 200px;
  height: 500px;
}

.slider {
  position: absolute;
  background-color: #d9ab28;
  bottom: 0;
  height: 5px;
  width: 95%;
  border-radius: 10px;
  transition: 0.9s;
  z-index: 10;
}
</style>
