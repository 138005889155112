<template>
  <div class="mx-auto px-4">
    <Nav />

    <div class="md:flex lg:flex sm:flex sm:object-center">
      <div class="lg:sticky md:sticky hidden sm:block sm:m-6 w-40">
        <score />
      </div>

      <div class="flex-1">
        <div>
          <h1
            class="m-6 font-bold font-bebas leading-7 text-center text-theme-1 text-4xl sm:truncate"
          >
            2021 National Fastpitch Leaderboard
          </h1>
          <div class="px-10">
            <div class="border-b border-gray-200">
              <nav class="-mb-px flex tab-navigation" aria-label="Tabs">
                <router-link
                  :to="`/national/${this.$route.params.sports}/hitting`"
                  class="border-transparent lg:text-2xl md:text-2xl sm:text-lg font-bebas font-bold text-theme-1 hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2"
                >
                  Hitting
                </router-link>
                <router-link
                  :to="`/national/${this.$route.params.sports}/pitching`"
                  append
                  class="border-transparent text-theme-1 lg:text-2xl md:text-2xl sm:text-lg font-bebas font-bold hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2"
                >
                  Pitching
                </router-link>
                <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                <router-link
                  :to="`/national/${this.$route.params.sports}/defense`"
                  class="border-transparent text-theme-1 hover:text-gray-700 lg:text-2xl md:text-2xl sm:text-lg font-bebas font-bold hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2"
                  aria-current="page"
                >
                  Defensive
                </router-link>
              </nav>
            </div>
          </div>
        </div>

        <div v-if="loading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            class="spinner"
          >
            <path d="M21 12a9 9 0 11-6.219-8.56" />
          </svg>
        </div>
        <div v-else>
          <router-view id="slkfjdslkfjl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import Nav from "components/Nav";
import Score from "components/Score";

// import LeaderBoard from "../components/LeaderBoard";

export default {
  name: "EventsPage",
  data() {
    return {
      loading: false,
      sharing: {
        url: window.location,
        title:
          "Say hi to Vite! A brand new, extremely fast development setup for Vue.",
        description:
          'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        quote: "The hot reload is so fast it's near instant. - Evan You",
        hashtags: "leadersboards,cse",
        twitterUser: "tprimmer"
      },
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "fab fah fa-lg fa-facebook-f",
          color: "#1877f2"
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fah fa-lg fa-twitter",
          color: "#1da1f2"
        }
      ]
    };
  },
  components: {
    Score,
    Nav
    // LeaderBoard
  },
  computed: {
    getSportName() {
      return this.$store.getters["events/getSportName"];
    }
  }
};
</script>

<style>
.spinner {
  stroke: black;
  animation-name: spinner;
  animation-duration: 0.8s;
  animation-direction: normal;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tab-navigation .router-link-active {
  border-color: #0b3253;
}
</style>
