import { createRouter, createWebHistory } from "vue-router";

import BlankPage from "./pages/Blank";

import Pitching from "components/Category/Pitching";
import Hitting from "components/Category/Hitting";
import Defense from "components/Category/Defense";

import Pitching2022 from "components/Category2022/Pitching";
import Hitting2022 from "components/Category2022/Hitting";
import Defense2022 from "components/Category2022/Defense";

import Pitching2023 from "components/Category2023/Pitching";
import Hitting2023 from "components/Category2023/Hitting";
import Defense2023 from "components/Category2023/Defense";

import PathwayPitching from "components/PathwayCategory/Pitching";
import PathwayHitting from "components/PathwayCategory/Hitting";
import PathwayDefense from "components/PathwayCategory/Defense";

import ObjectivePitching from "components/ObjectiveCategory/Pitching";
import ObjectiveHitting from "components/ObjectiveCategory/Hitting";
import ObjectiveDefense from "components/ObjectiveCategory/Defense";
import ObjectiveEventPage from "./pages/Objective/Events/EventPage";
import EventPage from "./pages/Events/EventPage";
import Events from "./pages/Events/Index";

import NationalIndex from "./pages/National/Index";
import National2022 from "./pages/National/National2022";
import National2023 from "./pages/National/National2023";
import ByYear from "./pages/National/ByYear";
import MQHitting from "./pages/MqEvents/Hitting.vue";
import MQPitching from "./pages/MqEvents/Pitching.vue";
import MQDefense from "./pages/MqEvents/Defense.vue";

import MQEventsPage from "./pages/MqEvents/EventsPage.vue";
import Pathway from "./pages/Pathway/Main.vue";
import PathwayEventPage from "./pages/Pathway/Events/EventPage";
const routes = [
  {
    path: "/",
    redirect: "/events"
  },
  {
    path: "/events/",
    component: Events
  },
  {
    path: "/national-leaderboards",
    component: ByYear
  },
  {
    path: "/pathway/",
    component: Pathway
  },
  {
    path: "/events/:sports/:eventId",
    component: EventPage,
    children: [
      {
        path: "hitting",
        component: Hitting
      },
      {
        path: "pitching",
        component: Pitching
      },
      {
        path: "defense",
        component: Defense
      }
    ]
  },
  {
    path: "/events/pathway/:eventId",
    component: PathwayEventPage,
    children: [
      {
        path: "hitting",
        component: PathwayHitting
      },
      {
        path: "pitching",
        component: PathwayPitching
      },
      {
        path: "defense",
        component: PathwayDefense
      }
    ]
  },
  {
    path: "/events/objective/:eventId",
    component: ObjectiveEventPage,
    children: [
      {
        path: "hitting",
        component: ObjectiveHitting
      },
      {
        path: "pitching",
        component: ObjectivePitching
      },
      {
        path: "defense",
        component: ObjectiveDefense
      }
    ]
  },

  {
    path: "/national/:sports",
    component: NationalIndex,
    children: [
      {
        path: "hitting",
        component: Hitting
      },
      {
        path: "pitching",
        component: Pitching
      },
      {
        path: "defense",
        component: Defense
      }
    ]
  },
  {
    path: "/national/:sports/2022",
    component: National2022,
    children: [
      {
        path: "hitting",
        component: Hitting2022
      },
      {
        path: "pitching",
        component: Pitching2022
      },
      {
        path: "defense",
        component: Defense2022
      }
    ]
  },
  {
    path: "/national/:sports/2023",
    component: National2023,
    children: [
      {
        path: "hitting",
        component: Hitting2023
      },
      {
        path: "pitching",
        component: Pitching2023
      },
      {
        path: "defense",
        component: Defense2023
      }
    ]
  },
  {
    path: "/mq-events",
    component: BlankPage,
    children: [
      {
        path: "hitting",
        component: MQHitting
      },
      {
        path: "pitching",
        component: MQPitching
      },
      {
        path: "defense",
        component: MQDefense
      },
      {
        path: ":sports/:eventId",
        component: MQEventsPage,
        children: [
          {
            path: "hitting",
            component: Hitting
          },
          {
            path: "pitching",
            component: Pitching
          },
          {
            path: "defense",
            component: Defense
          }
        ]
      }
    ]
  }
];

export default createRouter({
  base: "/",
  history: createWebHistory(),
  routes
});
