<template>
  <div>
    <!-- <VueSocialSharing /> -->
    <div class="mx-auto px-4">
      <div>
        <div class="flex flex-wrap overflow-hidden xl:-mx-1 p-4">
          <div
            class="w-full overflow-hidden xl:my-1 xl:px-1 xl:w-1/4 md:w-1/4 sm:w-1/4"
          >
            <!-- Column Content -->

            <div
              @click="toggleCategoryDropdown = !toggleCategoryDropdown"
              class="flex cursor-pointer flex-wrap my-1 justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            >
              Select A Category
              <svg
                class="-mr-1 ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div
              class="bg-white text-base z-50 float-left py-2 px-4 list-none text-left w-full rounded shadow-lg m-1"
              v-if="toggleCategoryDropdown"
            >
              <div class="flex flex-col items-left justify-left">
                <div v-if="!this.isMqEvent && !isBaseball">
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.category.cse"
                      type="checkbox"
                      id="CSE overall"
                      name="CSE overall"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-theme-1">CSE Overall</span>
                  </label>
                </div>
                <div v-if="!isBaseball">
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.category.pitchingsubjective"
                      type="checkbox"
                      id="pitchingsubjective"
                      name="pitchingsubjective"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-theme-1">Pitching Only</span>
                  </label>
                </div>
                <div>
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.category.pitchvelocity"
                      type="checkbox"
                      id="pitchvelocity"
                      name="pitchvelocity"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-theme-1">Pitch Velocity</span>
                  </label>
                </div>
                <div>
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.category.obj_spin_efficiency_max_median"
                      type="checkbox"
                      id="obj_spin_efficiency_max_median"
                      name="obj_spin_efficiency_max_median"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-theme-1"
                      >Median Spin Efficiency</span
                    >
                  </label>
                </div>
                <div>
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.category.verticalbreak"
                      type="checkbox"
                      id="verticalbreak"
                      name="verticalbreak"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-theme-1">Vertical Break</span>
                  </label>
                </div>
                <div>
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.category.horizontalbreak"
                      type="checkbox"
                      id="horizontalbreak"
                      name="horizontalbreak"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-theme-1">Horizontal Break</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-full overflow-hidden xl:my-1 xl:px-1 xl:w-1/4 md:w-1/4 sm:w-1/4"
          >
            <!-- Column Content -->
            <div
              @click="toggleGradYearDropdown = !toggleGradYearDropdown"
              class="flex cursor-pointer flex-wrap justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 my-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            >
              Filter by Grad Year
              <svg
                class="-mr-1 ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div
              class="bg-white text-base z-50 float-left py-2 px-4 list-none text-left w-full rounded shadow-lg m-1"
              v-if="toggleGradYearDropdown"
            >
              <div class="flex flex-col items-left justify-left">
                <div class="flex flex-col">
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.gradYears['2021']"
                      type="checkbox"
                      value="2021"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-theme-1">2021</span>
                  </label>

                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.gradYears['2022']"
                      type="checkbox"
                      value="2022"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-gray-700">2022</span>
                  </label>
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.gradYears['2023']"
                      type="checkbox"
                      value="2023"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-gray-700">2023</span>
                  </label>
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.gradYears['2024']"
                      type="checkbox"
                      value="2024"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-gray-700">2024</span>
                  </label>
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.gradYears['2025']"
                      type="checkbox"
                      value="2025"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-gray-700">2025</span>
                  </label>
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.gradYears['2026']"
                      type="checkbox"
                      value="2026"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-gray-700">2026</span>
                  </label>
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.gradYears['2027']"
                      type="checkbox"
                      value="2027"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-gray-700">2027</span>
                  </label>
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.gradYears['2028']"
                      type="checkbox"
                      value="2027"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-gray-700">2028</span>
                  </label>
                  <label class="inline-flex items-left mt-3">
                    <input
                      v-model="filters.gradYears['NotSubmitted']"
                      type="checkbox"
                      value="NotSubmitted"
                      class="form-checkbox h-5 w-5 text-theme-1"
                      checked
                    /><span class="ml-2 text-gray-700">Not Submitted</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-full overflow-hidden xl:my-1 xl:px-1 xl:w-1/4 md:w-1/4 sm:w-1/4"
          >
            <!-- Column Content -->
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                v-model="filters.gpaSearchValue"
                type="text"
                name="gpa"
                id="gpa"
                class="focus:ring-theme-1 focus:border-theme-1 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                placeholder="Input GPA"
                @keypress="onlyNumber"
              />
              <div class="absolute inset-y-0 right-0 flex items-center">
                <label for="filter" class="sr-only">Filter By</label>
                <select
                  v-model="filters.gpaSearchType"
                  id="filter"
                  name="filter"
                  class="cursor-pointer focus:ring-theme-1 focus:border-theme-1 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                >
                  <option value="0">No Condition</option>
                  <option value="1">Greater Than</option>
                  <option value="2">Less Than</option>
                  <option value="3">Equal To</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pitching-leaderboard
        :data="this.filteredPlayers"
        @click="scoreClick"
        :showCse="filters.category.cse"
        :showPitchingSubjective="filters.category.pitchingsubjective"
        :showPitchVelocity="filters.category.pitchvelocity"
        :showSpinRate="filters.category.obj_spin_efficiency_max_median"
        :showVerticalBreak="filters.category.verticalbreak"
        :showHorizontalBreak="filters.category.horizontalbreak"
      />
    </div>
    <div v-if="positionFilterErrorMessage">Please select a position.</div>
  </div>
</template>

<script>
import Popper from "popper.js";
// import VueSocialSharing from "vue-social-sharing";
import axios from "axios";
import PitchingLeaderboard from "@/components/LeaderBoards/PitchingLeaderboard";
import { useActiveMeta, useMeta } from "vue-meta";

export default {
  name: "PitchingEventPage",
  data() {
    return {
      eventId: "",
      isBaseball: false,
      toggleFilterDropdown: false,
      toggleGradYearDropdown: false,
      toggleCategoryDropdown: false,
      initFilters: true,
      players: [],
      colDataFetchingLoading: false,
      colDataFetchingError: false,
      positionFilter: ["Pitcher"],
      showFilter: true,
      selectedScore: 0,
      positionFilterErrorMessage: false,
      pageNum: 1,
      highlightColName: "",
      filterRoute: [
        "cse",
        "pitchingsubjective",
        "pitchvelocity",
        "obj_spin_efficiency_max_median",
        "verticalbreak",
        "horizontalbreak"
      ],
      filters: {
        filter: this.$route.query.filter || "",
        showCategory: true,
        category: {
          cse: true,
          pitchingsubjective: true,
          pitchvelocity: true,
          obj_spin_efficiency_max_median: true,
          verticalbreak: true,
          horizontalbreak: true
        },
        gradYears: {
          "2021": true,
          "2022": true,
          "2023": true,
          "2024": true,
          "2025": true,
          "2026": true,
          "2027": true,
          "2028": true,
          NotSubmitted: true
        },
        gpaSearchValue: "",
        gpaSearchType: 0
      },
      sortCol: {
        name: this.isMqEvent
          ? "velocity"
          : "subj_collegefit_overall_scoreweighted_conv2080",
        order: "desc"
      },
      metaObj: Object
    };
  },
  components: { PitchingLeaderboard },
  computed: {
    getEventId() {
      return this.$store.getters["events/getEventId"];
    },
    isNational() {
      return this.$route.path.match(/national/);
    },
    isMqEvent() {
      return this.$route.path.match(/mq-events/);
    },
    isDataExists() {
      return this.$store.getters["events/getRenderingState"].data;
    },
    getActiveColName() {
      return this.$store.getters["events/activeColName"];
    },
    getFilters(filter) {
      return this.filterRoute.includes(filter);
    },
    getVersion() {
      return this.$store.getters["events/getSlots"];
    },
    filteredPlayers() {
      const type = parseInt(this.filters.gpaSearchType);
      const value = parseFloat(this.filters.gpaSearchValue) || 0;
      const filteredByGpa = this.players.filter(player => {
        const gpa = parseFloat(player.gpa) || 0;
        if (type === 0) return true;
        else if (type === 1) return gpa > value;
        else if (type === 2) return gpa < value;
        else return gpa === value;
      });
      const gradYearValues = Object.keys(this.filters.gradYears).filter(key => {
        return this.filters.gradYears[key];
      });
      return filteredByGpa.filter(player => {
        return (
          gradYearValues.indexOf(String(player.gradyear).replace(" ", "")) > -1
        );
      });
    }
  },
  methods: {
    async getEventData() {
      if (this.$route.params.eventId) {
        try {
          const url = `https://cse-api.herokuapp.com/products/getevent/${this.$route.params.eventId}`;

          const response = await axios({
            method: "GET",
            url: url
          });
          this.eventId = response.data.event[0].cse_eventid;
          this.metaObj.title = response.data.event[0].eventname;
          this.metaObj.og.title = response.data.event[0].eventname;

          this.getEvents();
        } catch (error) {
          console.log(error);
        }
      } else {
        this.getEvents();
      }
    },
    async getEvents() {
      this.$store.commit("events/updateRendering", {
        loading: true
      });

      this.colDataFetchingLoading = true;
      this.loading = true;
      try {
        this.players = [];

        const postionParams = [];

        for (const key in this.filters.position) {
          if (this.filters.position[key]) {
            postionParams.push(key);
          }
        }
        let url = `https://cse-api.herokuapp.com/leaderboard/${this.$route.params.sports.toLowerCase()}/pitching${
          this.eventId ? "/" + this.eventId : ""
        }`;
        if (this.isMqEvent)
          url = `https://cse-api.herokuapp.com/liveleaderboardapi/${this.$route.params.sports.toLowerCase()}/mq/pitching/${
            this.eventId
          }`;
        const players = await axios({
          method: "GET",
          url: url,
          params: {
            tg_position: "Pitcher",
            sort: `${this.sortCol.name}:${this.sortCol.order}`
          }
        });
        const data = this.isMqEvent
          ? players.data.liveleaderboard
          : players.data.leaderboard;
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          const item = {
            id: element.cse_playerid,
            name: element.first_name,
            lastName: element.last_name,
            gpa: element.gpa,
            gradyear: element.graduation_year,
            cseScore: element.subj_collegefit_overall_scoreweighted_conv2080,
            pitchingSubjective:
              element.subj_collegefitpitchers_po_scoreweighted_conv2080,
            pitchVelocity: element.velocity,
            obj_spin_efficiency_max_median:
              element.obj_spin_efficiency_max_median,
            verticalBreak: element.vertical_break,
            horizontalBreak: element.horizontal_break,
            sport: element.tg_position
          };
          if (this.eventId) item.eventName = element.cse_eventid.eventname;
          this.players.push(item);
        }

        this.$store.commit("events/updateRendering", {
          data: true
        });
      } catch (error) {
        this.$store.commit("events/updateRendering", {
          error: true
        });
      } finally {
        this.loading = false;
        this.initFilters = false;
        this.$store.commit("events/updateRendering", {
          loading: false
        });
      }
    },
    loadMore() {
      // this.pageNum++
      this.pageNum = this.pageNum + 1;
      this.getEvents();
    },
    scoreClick(event) {
      const score = parseFloat(event);
      // this.selectedScore = (3 / 5) * score + 20;
      this.selectedScore = score;
    },
    updateFilter() {
      const categoryFilter = this.$route.query.category?.split(",");
      const gradYearsFilter = this.$route.query.gradYears?.split(",");
      // category filter
      const categoryObj = this.filters.category;

      for (const key in categoryObj) {
        this.filters.category[key] = false;
        if (categoryFilter.includes(key)) {
          this.filters.category[key] = true;
        }
      }
      // gradYear Filter
      const gradYearsObj = this.filters.gradYears;
      for (const key in gradYearsObj) {
        this.filters.gradYears[key] = false;
        if (gradYearsFilter.includes(key)) {
          this.filters.gradYears[key] = true;
        }
      }
      this.filters.gpaSearchType = this.$route.query.gpaSearchType;
      this.filters.gpaSearchValue = this.$route.query.gpaSearchValue;
    },
    toggleDropdown: function() {
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        new Popper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start"
        });
      }
    },

    togglePositionDropdown: function() {
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        new Popper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start"
        });
      }
    },

    highlightColumn(e) {
      this.highlightColName = e;

      for (let i = 0; i < this.players.length; i++) {
        // const item = this.players[i];
      }
    },
    onlyNumber($event) {
      // console.log($event.keyCode); //keyCodes value
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    updateRouteURL() {
      const category = this.filters.category;
      const categoryFilter = Object.keys(category)
        .filter(id => {
          if (this.isMqEvent && id === "cse") return false;
          return category[id];
        })
        .join(",");

      const gradYears = this.filters.gradYears;
      const gradYearsFilter = Object.keys(gradYears)
        .filter(id => {
          return gradYears[id];
        })
        .join(",");
      const query = {
        category: categoryFilter,
        gradYears: gradYearsFilter
      };
      if (this.filters.gpaSearchType) {
        query.gpaSearchType = this.filters.gpaSearchType;
      }
      if (this.filters.gpaSearchValue) {
        query.gpaSearchValue = this.filters.gpaSearchValue;
      }
      this.$router.push({
        path: this.$route.path,
        query: query
      });
    }
  },
  watch: {
    getActiveColName: {
      handler(newVal) {
        this.sortCol.name = newVal;
        if (!this.initFilters) this.getEvents();
      }
    },
    "filters.category": {
      handler(newVal) {
        this.updateRouteURL();
      },
      deep: true
    },
    "filters.gradYears": {
      handler(newVal) {
        this.updateRouteURL();
      },
      deep: true
    },
    "filters.gpaSearchType": {
      handler(newVal) {
        this.updateRouteURL();
      }
    },
    "filters.gpaSearchValue": {
      handler(newVal) {
        this.updateRouteURL();
      }
    }
  },
  mounted() {
    // mutation for the selected col in vuex
    this.$store.commit(
      "events/updateColName",
      this.isMqEvent
        ? "velocity"
        : "subj_collegefit_overall_scoreweighted_conv2080"
    );
    const { meta } = useMeta({
      title: "Pitching",
      og: {
        title: "Pitching"
      }
    });
    this.metaObj = meta;
    this.isBaseball = this.$route.path.match(/Baseball/);
    this.isBaseball = this.$route.path.match(/baseball/);
  },
  created() {
    if (
      this.$route.query.category ||
      this.$route.query.gradYears ||
      this.$route.query.gpaSearchType ||
      this.$route.query.gpaSearchValue
    ) {
      this.updateFilter();
    }
    this.getEventData();
  }
};
</script>

<style lang="css">
div.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  padding-top: 20px;
}

.checkbox-input {
  display: none;
}
.checkbox {
  border: 1px solid red;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}
</style>
