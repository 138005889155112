const state = {
  score: 0,
  fade: false,
  selected: {
    index: "",
    colName: "",
    id: ""
  }
};

const getters = {
  getScore: state => state.score,
  getSelected: state => state.selected
};

const mutations = {
  updateScore(state, payload) {
    state.score = payload;
  },
  updateSelection(state, payload) {
    state.selected.index = payload.index;
    state.selected.id = payload.id;
    state.selected.colName = payload.colName;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
