<template>
  <div>
    <div
      class="my-2 p-3 sm:-mx-6 lg:-mx-8 md:-mx-8 bg-white overflow-x-auto z-0"
    >
      <div
        class="py-2 md:px-52 align-middle inline-block min-w-full sm:px-6 lg:px-8"
      >
        <div class=" border-b shadow border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 bg-gray-50 text-left text-xs table-fixed font-medium text-gray-500 uppercase tracking-wider"
                >
                  Rank
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 bg-gray-50 text-left text-xs table-fixed font-medium text-gray-500 uppercase tracking-wider"
                >
                  Player Name
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold':
                      getActiveCol ===
                      'subj_collegefit_overall_scoreweighted_conv2080'
                  }"
                  v-if="!isMqEvent && category.cse && !isBaseball"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="
                    activeColClickHandler(
                      'subj_collegefit_overall_scoreweighted_conv2080'
                    )
                  "
                  style="display: flex; align-items: center;"
                >
                  CSE Overall
                </th>

                <th
                  :class="{
                    'text-theme-1 font-bold':
                      getActiveCol === 'subj_collegefit_defense_scoreconv2080'
                  }"
                  v-if="
                    getVersion === 1 &&
                      category.defensivesubjective &&
                      !isBaseball
                  "
                  scope="col"
                  class="px-3 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="
                    activeColClickHandler(
                      'subj_collegefit_defense_scoreconv2080'
                    )
                  "
                >
                  Defensive Score
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold': getActiveCol === 'max_arm_velo'
                  }"
                  v-if="category.max_arm_velo"
                  scope="col"
                  class="px-3 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('max_arm_velo')"
                >
                  Median Arm Velo
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold': getActiveCol === 'home_to_1'
                  }"
                  v-if="category.hometofirst && !isBaseball"
                  scope="col"
                  class="px-3 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('home_to_1')"
                >
                  Home to First
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold': getActiveCol === 'home_to_home'
                  }"
                  v-if="category.hometohome && !isBaseball"
                  scope="col"
                  class="px-3 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('home_to_home')"
                >
                  Home to Home
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold': getActiveCol === 'min_60'
                  }"
                  v-if="category.sixtytime && isBaseball"
                  scope="col"
                  class="px-3 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('min_60')"
                >
                  60 Yard Dash
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold': getActiveCol === 'min_pop_time'
                  }"
                  v-if="category.poptime"
                  scope="col"
                  class="px-3 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('min_pop_time')"
                >
                  Pop Time
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(player, index) in data"
                :key="player.cse_playerid"
                class="player bg-white border-b border-gray-200 sm:rounded-lgshaddow min-w-full divide-y divide-gray-200"
                :class="{
                  selectFullRow: getSelection.id === player.id
                }"
              >
                <td
                  class="px-6 py-4 bg-white whitespace-nowrap text-sm font-medium text-gray-900"
                  :class="{
                    'text-theme-40 bg-theme-1 shadow-md':
                      getSelection.id === player.id
                  }"
                >
                  {{ index + 1 }}
                </td>
                <td
                  :class="{
                    'text-theme-40 bg-theme-1': getSelection.id === player.id
                  }"
                  class="lg:px-6 pr-2 pl-1 py-4 bg-white z-49 whitespace-nowrap text-sm text-gray-500"
                >
                  <p
                    :class="{
                      'text-theme-40 bg-theme-1': getSelection.id === player.id
                    }"
                    class="text-md font-medium text-theme-1"
                  >
                    {{ player.name }} {{ player.lastName }}
                  </p>
                  <p class="text-sm text-gray-500">
                    {{ player.sport }} <br />
                    GPA: {{ player.gpa }} <br />
                    Graduation Year:
                    {{ player.gradyear }}
                  </p>
                </td>
                <td
                  v-if="!isMqEvent && category.cse && !isBaseball"
                  @click="
                    emit(player.cseScore, 'subj_collegefit_overall_score', {
                      index,
                      id: player.id
                    })
                  "
                  :class="{
                    selectedRow:
                      getSelection.colName ===
                        'subj_collegefit_overall_score' &&
                      getSelection.id === player.id
                  }"
                  class="px-6 py-4 cursor-pointer whitespace-nowrap text-sm text-gray-500"
                >
                  <div
                    v-if="player.cseScore > 0"
                    class="flex"
                    style="align-items: center;"
                  >
                    {{ player.cseScore }} / 80
                    <svg
                      style="margin-left: 10px;"
                      class="w-4 h-4"
                      v-if="getSelection.id === player.id"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 162.02 153.7"
                    >
                      <path
                        d="M64.93 74.61l-4.1-17.28L90.2 27.96l7.14 21.76-32.41 24.89zM78.13 4.6L63.25 49.96l23.89-31.32L82.53 4.6c-.7-2.13-3.7-2.13-4.4 0zM48.78 94.42l17.19.96-13.29-38.03-22.76.06 18.86 37.01zM3.97 61.68l38.16 27.89-22.68-32.12-14.12.04c-2.24 0-3.17 2.86-1.36 4.19zm35.34 62.17l41.02-6.5 5.71-16.53-39.69 1.16-7.04 21.87zm-3.97 25.99l38.2-27.59-37.52 11.83-4.24 13.17c-.69 2.14 1.74 3.9 3.56 2.59zm76.54-55.42L97.41 83.4l-10.87 38.43 18.85 13.61 6.49-41.02zm2.32 7.2l-.62 39.75 11.74 8.48c1.81 1.31 4.25-.46 3.56-2.59l-14.68-45.64zM84.36 67.21l33.74 22.67 18.73-13.69-37-18.86-15.47 9.88zm70.97-9.72l-48.05-.14 37.34 13.15 12.07-8.82c1.81-1.33.88-4.19-1.36-4.19z"
                        fill="#d8ab28"
                      />
                    </svg>
                  </div>
                </td>
                <td
                  v-if="
                    getVersion === 1 &&
                      category.defensivesubjective &&
                      !isBaseball
                  "
                  @click="
                    emit(
                      player.defensiveSubjective,
                      'cse_defensive_subjective',
                      {
                        index,
                        id: player.id
                      }
                    )
                  "
                  :class="{
                    selectedRow:
                      getSelection.colName === 'cse_defensive_subjective' &&
                      getSelection.id === player.id
                  }"
                  class="px-6 py-4 cursor-pointer whitespace-nowrap text-sm text-gray-500"
                >
                  <div
                    v-if="player.defensiveSubjective > 0"
                    class="flex"
                    style="align-items: center;"
                  >
                    {{ player.defensiveSubjective }} / 80
                    <svg
                      style="margin-left: 10px;"
                      class="w-4 h-4"
                      v-if="getSelection.id === player.id"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 162.02 153.7"
                    >
                      <path
                        d="M64.93 74.61l-4.1-17.28L90.2 27.96l7.14 21.76-32.41 24.89zM78.13 4.6L63.25 49.96l23.89-31.32L82.53 4.6c-.7-2.13-3.7-2.13-4.4 0zM48.78 94.42l17.19.96-13.29-38.03-22.76.06 18.86 37.01zM3.97 61.68l38.16 27.89-22.68-32.12-14.12.04c-2.24 0-3.17 2.86-1.36 4.19zm35.34 62.17l41.02-6.5 5.71-16.53-39.69 1.16-7.04 21.87zm-3.97 25.99l38.2-27.59-37.52 11.83-4.24 13.17c-.69 2.14 1.74 3.9 3.56 2.59zm76.54-55.42L97.41 83.4l-10.87 38.43 18.85 13.61 6.49-41.02zm2.32 7.2l-.62 39.75 11.74 8.48c1.81 1.31 4.25-.46 3.56-2.59l-14.68-45.64zM84.36 67.21l33.74 22.67 18.73-13.69-37-18.86-15.47 9.88zm70.97-9.72l-48.05-.14 37.34 13.15 12.07-8.82c1.81-1.33.88-4.19-1.36-4.19z"
                        fill="#d8ab28"
                      />
                    </svg>
                  </div>
                </td>
                <td
                  v-if="category.max_arm_velo"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <p v-if="player.max_arm_velo > 0">
                    {{ player.max_arm_velo }} mph
                  </p>
                </td>
                <td
                  v-if="category.hometofirst && !isBaseball"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <p v-if="player.hometoFirst > 0">
                    {{ player.hometoFirst }} sec
                  </p>
                </td>
                <td
                  v-if="category.hometohome && !isBaseball"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <p v-if="player.hometoHome > 0">
                    {{ player.hometoHome }} sec
                  </p>
                </td>
                <td
                  v-if="category.sixtytime && isBaseball"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <p v-if="player.sixtytime > 0">{{ player.sixtytime }} sec</p>
                </td>
                <td
                  v-if="category.poptime"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <p v-if="player.popTime > 0">{{ player.popTime }} sec</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  name: "LeaderBoard",
  data() {
    return {
      loading: false,
      filter: {
        cse: true,
        defensivesubjective: true,
        max_arm_velo: true,
        hometofirst: true,
        hometohome: true,
        poptime: true,
        sixtytime: true
      },
      tooltipShow: false,
      isMqEvent: false,
      isBaseball: false
    };
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    category: {
      type: Object,
      required: true
    },
    position: {
      type: Object,
      required: true
    },

    showCse: Boolean,
    showDefensiveSub: Boolean,
    showOverhandVelo: Boolean,
    showHometoFirst: Boolean,
    showHometoHome: Boolean,
    showSixtyTime: Boolean,
    showPopTime: Boolean
  },
  computed: {
    getSelection() {
      return this.$store.getters["score/getSelected"];
    },
    getActiveCol() {
      return this.$store.getters["events/activeColName"];
    },
    getVersion() {
      return this.$store.getters["events/getSlots"];
    }
  },
  methods: {
    emit(data, object, index) {
      console.log(index);

      let value = parseFloat(data);
      if (["obj_footspeed_h11", "obj_poptime_throw2"].includes(object)) {
        value = 0;
      }
      this.$store.commit("score/updateSelection", {
        index: index.index,
        id: index.id,
        colName: object
      });

      this.$store.commit("score/updateScore", value);
    },
    highlightColumn(colName) {
      this.$emit("highlight-col", colName);
    },
    activeColClickHandler(colName) {
      this.$store.commit("events/updateColName", colName);
    },
    toggleTooltip: function() {
      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "right"
        });
      }
    },
    showTooltip(flag) {
      switch (flag) {
        case 1:
          document.getElementById("tooltip1").classList.remove("opacity-0");
          break;
      }
    },
    hideTooltip(flag) {
      switch (flag) {
        case 1:
          document.getElementById("tooltip1").classList.add("opacity-0");
          break;
      }
    }
  },
  watch: {
    category: {
      handler(newVal) {
        console.log(newVal);
      },
      deep: true
    },
    position: {
      handler(newVal) {
        console.log(newVal);
      },
      deep: true
    },
    showCse: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          cse: newVal
        };
      }
    },
    showDefensiveSub: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          defensivesubjective: newVal
        };
      }
    },
    showOverhandVelo: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          max_arm_velo: newVal
        };
      }
    },
    showHometoFirst: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          hometofirst: newVal
        };
      }
    },
    showHometoHome: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          hometohome: newVal
        };
      }
    },
    showSixtyTime: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          min_60: newVal
        };
      }
    },
    showPopTime: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          poptime: newVal
        };
      }
    }
  },
  mounted() {
    this.isMqEvent = this.$route.path.match(/mq-events/);
    this.isBaseball = this.$route.path.match(/Baseball/);
    this.isBaseball = this.$route.path.match(/baseball/);
  }
};
</script>

<style lang="css" scoped>
.player {
  margin-bottom: 1rem;
}

.col-cse {
  background: red;
  margin-bottom: 1rem;
}

.selectedRow {
  color: #d9ab28;
  font-weight: bold;
}

.selectFullRow {
  background-color: #0b3253;
  box-shadow: 1px 3px #f3f3f3;
}
</style>
