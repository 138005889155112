<template>
  <div>
    <div class="my-2 sm:-mx-6 lg:-mx-8 md:-mx-8 bg-white overflow-x-auto">
      <div
        class="py-2 md:px-52 align-middle inline-block min-w-full sm:px-6 lg:px-8"
      >
        <div class="border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full shadow-md divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 bg-gray-50 text-left text-xs table-fixed font-medium text-gray-500 uppercase tracking-wider"
                >
                  Rank
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 bg-gray-50 text-left text-xs table-fixed font-medium text-gray-500 uppercase tracking-wider"
                >
                  Player Name
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold':
                      getActiveCol ===
                      'subj_collegefit_overall_scoreweighted_conv2080'
                  }"
                  v-if="!isMqEvent && showCse && !isBaseball"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="
                    activeColClickHandler(
                      'subj_collegefit_overall_scoreweighted_conv2080'
                    )
                  "
                  style="display: flex; align-items: center"
                >
                  CSE Overall
                  <!-- <div class=" text-center">
                    <button
                      ref="btnRef"
                      v-on:mouseenter="toggleTooltip()"
                      v-on:mouseleave="toggleTooltip()"
                      class=""
                      type="button"
                    >
                      <img
                        class="h-6 w-auto sm:h-6"
                        src="@/assets/icon2.png"
                        alt=""
                      />
                    </button>

                    <div
                      ref="tooltipRef"
                      v-bind:class="{
                        hidden: !tooltipShow,
                        block: tooltipShow,
                      }"
                      class="bg-theme-1 border-0 ml-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
                    >
                      <div>
                        <div
                          class="bg-theme-1 cursor-pointer text-white font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg"
                        >
                          CSE Overall Score
                        </div>
                        <div
                          class="text-white p-3 font-sm font-normal text-transform: none;"
                        >
                          The CSE Overall Score is given to a player that has
                          attended a Full CSE. <br />
                          <br />
                          It is on a 20-80 scale and is comprised of a weighted
                          average of individual pillar scores based upon
                          position. <br />
                          <br />
                          An athlete that attended a Hitting Only, Pitching
                          Only, or Defensive Only CSE will not have a CSE
                          Overall Score.
                        </div>
                      </div>
                    </div>
                  </div> -->
                </th>

                <th
                  :class="{
                    'text-theme-1 font-bold':
                      getActiveCol ===
                      'subj_collegefitpitchers_po_scoreweighted_conv2080'
                  }"
                  v-if="
                    getVersion === 1 && showPitchingSubjective && !isBaseball
                  "
                  scope="col"
                  class="px-6 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="
                    activeColClickHandler(
                      'subj_collegefitpitchers_po_scoreweighted_conv2080'
                    )
                  "
                >
                  Pitching Only
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold': getActiveCol === 'velocity'
                  }"
                  v-if="showPitchVelocity"
                  scope="col"
                  class="px-6 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('velocity')"
                >
                  Pitch Velocity
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold':
                      getActiveCol === 'obj_spin_efficiency_max_median'
                  }"
                  v-if="showSpinRate"
                  scope="col"
                  class="px-6 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="
                    activeColClickHandler('obj_spin_efficiency_max_median')
                  "
                >
                  Median Spin Efficiency
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold': getActiveCol === 'vertical_break'
                  }"
                  v-if="showVerticalBreak"
                  scope="col"
                  class="px-6 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('vertical_break')"
                >
                  Vert. Break
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold':
                      getActiveCol === 'horizontal_break'
                  }"
                  v-if="showHorizontalBreak"
                  scope="col"
                  class="px-6 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('horizontal_break')"
                >
                  Hor. Break
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(player, index) in data"
                :key="player.cse_playerid"
                class="player bg-white shadow border-b border-gray-200 sm:rounded-lg min-w-full divide-y divide-gray-200"
                :class="{
                  selectFullRow: getSelection.id === player.id
                }"
              >
                <td
                  class="px-6 py-4 z-50 bg-white whitespace-nowrap text-sm font-medium text-gray-900"
                  :class="{
                    'text-theme-40 bg-theme-1': getSelection.id === player.id
                  }"
                >
                  {{ index + 1 }}
                </td>
                <td
                  :class="{
                    'text-theme-40 bg-theme-1 z-51':
                      getSelection.id === player.id
                  }"
                  class="lg:px-6 pr-2 pl-1 py-4 bg-white z-49 whitespace-nowrap text-sm text-gray-500"
                >
                  <p
                    :class="{
                      'text-theme-40 bg-theme-1 z-51':
                        getSelection.id === player.id
                    }"
                    class="text-md font-medium text-theme-1"
                  >
                    {{ player.name }} {{ player.lastName }}
                  </p>
                  <p
                    :class="{
                      'text-theme-40 bg-theme-1 z-51':
                        getSelection.id === player.id
                    }"
                    class="text-sm text-gray-500"
                  >
                    {{ player.sport }} <br />
                    GPA: {{ player.gpa }} <br />
                    Graduation Year:
                    {{ player.gradyear }}
                  </p>
                </td>
                <td
                  v-if="!isMqEvent && showCse && !isBaseball"
                  @click="
                    emit(player.cseScore, 'subj_collegefit_overall_score', {
                      index,
                      id: player.id
                    })
                  "
                  :class="{
                    selectedRow:
                      getSelection.colName ===
                        'subj_collegefit_overall_score' &&
                      getSelection.id === player.id
                  }"
                  class="px-6 py-4 cursor-pointer whitespace-nowrap text-sm text-gray-500"
                >
                  <div class="flex" style="align-items: center">
                    {{ player.cseScore }} / 80
                    <svg
                      style="margin-left: 10px"
                      class="w-4 h-4"
                      v-if="getSelection.id === player.id"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 162.02 153.7"
                    >
                      <path
                        d="M64.93 74.61l-4.1-17.28L90.2 27.96l7.14 21.76-32.41 24.89zM78.13 4.6L63.25 49.96l23.89-31.32L82.53 4.6c-.7-2.13-3.7-2.13-4.4 0zM48.78 94.42l17.19.96-13.29-38.03-22.76.06 18.86 37.01zM3.97 61.68l38.16 27.89-22.68-32.12-14.12.04c-2.24 0-3.17 2.86-1.36 4.19zm35.34 62.17l41.02-6.5 5.71-16.53-39.69 1.16-7.04 21.87zm-3.97 25.99l38.2-27.59-37.52 11.83-4.24 13.17c-.69 2.14 1.74 3.9 3.56 2.59zm76.54-55.42L97.41 83.4l-10.87 38.43 18.85 13.61 6.49-41.02zm2.32 7.2l-.62 39.75 11.74 8.48c1.81 1.31 4.25-.46 3.56-2.59l-14.68-45.64zM84.36 67.21l33.74 22.67 18.73-13.69-37-18.86-15.47 9.88zm70.97-9.72l-48.05-.14 37.34 13.15 12.07-8.82c1.81-1.33.88-4.19-1.36-4.19z"
                        fill="#d8ab28"
                      />
                    </svg>
                  </div>
                </td>
                <td
                  v-if="
                    getVersion === 1 && showPitchingSubjective && !isBaseball
                  "
                  @click="
                    emit(
                      player.pitchingSubjective,
                      'subj_collegefitpitchers_po_scoreweighted_conv2080',
                      {
                        index,
                        id: player.id
                      }
                    )
                  "
                  :class="{
                    selectedRow:
                      getSelection.colName ===
                        'subj_collegefitpitchers_po_scoreweighted_conv2080' &&
                      getSelection.id === player.id
                  }"
                  class="px-6 py-4 cursor-pointer whitespace-nowrap text-sm text-gray-500"
                >
                  <div class="flex" style="align-items: center">
                    {{ player.pitchingSubjective }} / 80
                    <svg
                      style="margin-left: 10px"
                      class="w-4 h-4"
                      v-if="getSelection.id === player.id"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 162.02 153.7"
                    >
                      <path
                        d="M64.93 74.61l-4.1-17.28L90.2 27.96l7.14 21.76-32.41 24.89zM78.13 4.6L63.25 49.96l23.89-31.32L82.53 4.6c-.7-2.13-3.7-2.13-4.4 0zM48.78 94.42l17.19.96-13.29-38.03-22.76.06 18.86 37.01zM3.97 61.68l38.16 27.89-22.68-32.12-14.12.04c-2.24 0-3.17 2.86-1.36 4.19zm35.34 62.17l41.02-6.5 5.71-16.53-39.69 1.16-7.04 21.87zm-3.97 25.99l38.2-27.59-37.52 11.83-4.24 13.17c-.69 2.14 1.74 3.9 3.56 2.59zm76.54-55.42L97.41 83.4l-10.87 38.43 18.85 13.61 6.49-41.02zm2.32 7.2l-.62 39.75 11.74 8.48c1.81 1.31 4.25-.46 3.56-2.59l-14.68-45.64zM84.36 67.21l33.74 22.67 18.73-13.69-37-18.86-15.47 9.88zm70.97-9.72l-48.05-.14 37.34 13.15 12.07-8.82c1.81-1.33.88-4.19-1.36-4.19z"
                        fill="#d8ab28"
                      />
                    </svg>
                  </div>
                </td>
                <td
                  v-if="showPitchVelocity"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <div class="flex" style="align-items: center">
                    {{ player.pitchVelocity }} mph
                    <svg
                      style="margin-left: 10px"
                      class="w-4 h-4 flex"
                      v-if="getSelection.id === player.id"
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 37.66 41.25"
                    >
                      <path
                        fill="#9e9e9e"
                        class="cls-1"
                        d="M7.1 23.49h6.67v1.67H7.1zM3.44 20.15h10.32v1.67H3.44zM7.1 13.48h6.67v1.66H7.1zM7.1 10.14h6.67v1.67H7.1zM7.1 16.82h6.67v1.67H7.1zM7.1 33.5h6.67v1.67H7.1zM3.44 36.83h10.32v1.67H3.44zM7.1 26.83h6.67v1.67H7.1zM7.1 30.17h6.67v1.66H7.1zM7.1 6.8h6.67v1.67H7.1zM3.44 3.47h10.32v1.66H3.44zM28.85 22.07a10 10 0 003.23-7.78c0-4.15-2.5-10.82-11.62-10.82h-5v6.67h1.67c5 0 5.83 2.67 5.83 4.68s-1.66 4.5-5.83 4.5h-1.71v5.84l8.34 13.34h11.66l-11-14.34a9 9 0 004.45-2.09"
                      />
                    </svg>
                  </div>
                </td>
                <td
                  v-if="showSpinRate"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <div class="flex" style="align-items: center">
                    {{ player.obj_spin_efficiency_max_median }} %
                    <svg
                      style="margin-left: 10px"
                      class="w-4 h-4 flex"
                      v-if="getSelection.id === player.id"
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 37.66 41.25"
                    >
                      <path
                        fill="#9e9e9e"
                        class="cls-1"
                        d="M7.1 23.49h6.67v1.67H7.1zM3.44 20.15h10.32v1.67H3.44zM7.1 13.48h6.67v1.66H7.1zM7.1 10.14h6.67v1.67H7.1zM7.1 16.82h6.67v1.67H7.1zM7.1 33.5h6.67v1.67H7.1zM3.44 36.83h10.32v1.67H3.44zM7.1 26.83h6.67v1.67H7.1zM7.1 30.17h6.67v1.66H7.1zM7.1 6.8h6.67v1.67H7.1zM3.44 3.47h10.32v1.66H3.44zM28.85 22.07a10 10 0 003.23-7.78c0-4.15-2.5-10.82-11.62-10.82h-5v6.67h1.67c5 0 5.83 2.67 5.83 4.68s-1.66 4.5-5.83 4.5h-1.71v5.84l8.34 13.34h11.66l-11-14.34a9 9 0 004.45-2.09"
                      />
                    </svg>
                  </div>
                </td>
                <td
                  v-if="showVerticalBreak"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <div class="flex" style="align-items: center;">
                    {{ player.verticalBreak }} inches
                    <svg
                      style="margin-left: 10px"
                      class="w-4 h-4 flex"
                      v-if="getSelection.id === player.id"
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 37.66 41.25"
                    >
                      <path
                        fill="#9e9e9e"
                        class="cls-1"
                        d="M7.1 23.49h6.67v1.67H7.1zM3.44 20.15h10.32v1.67H3.44zM7.1 13.48h6.67v1.66H7.1zM7.1 10.14h6.67v1.67H7.1zM7.1 16.82h6.67v1.67H7.1zM7.1 33.5h6.67v1.67H7.1zM3.44 36.83h10.32v1.67H3.44zM7.1 26.83h6.67v1.67H7.1zM7.1 30.17h6.67v1.66H7.1zM7.1 6.8h6.67v1.67H7.1zM3.44 3.47h10.32v1.66H3.44zM28.85 22.07a10 10 0 003.23-7.78c0-4.15-2.5-10.82-11.62-10.82h-5v6.67h1.67c5 0 5.83 2.67 5.83 4.68s-1.66 4.5-5.83 4.5h-1.71v5.84l8.34 13.34h11.66l-11-14.34a9 9 0 004.45-2.09"
                      />
                    </svg>
                  </div>
                </td>

                <td
                  v-if="showHorizontalBreak"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <div class="flex" style="align-items: center;">
                    {{ player.horizontalBreak }} inches
                    <svg
                      style="margin-left: 10px"
                      class="w-4 h-4 flex"
                      v-if="getSelection.id === player.id"
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 37.66 41.25"
                    >
                      <path
                        fill="#9e9e9e"
                        class="cls-1"
                        d="M7.1 23.49h6.67v1.67H7.1zM3.44 20.15h10.32v1.67H3.44zM7.1 13.48h6.67v1.66H7.1zM7.1 10.14h6.67v1.67H7.1zM7.1 16.82h6.67v1.67H7.1zM7.1 33.5h6.67v1.67H7.1zM3.44 36.83h10.32v1.67H3.44zM7.1 26.83h6.67v1.67H7.1zM7.1 30.17h6.67v1.66H7.1zM7.1 6.8h6.67v1.67H7.1zM3.44 3.47h10.32v1.66H3.44zM28.85 22.07a10 10 0 003.23-7.78c0-4.15-2.5-10.82-11.62-10.82h-5v6.67h1.67c5 0 5.83 2.67 5.83 4.68s-1.66 4.5-5.83 4.5h-1.71v5.84l8.34 13.34h11.66l-11-14.34a9 9 0 004.45-2.09"
                      />
                    </svg>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  name: "PitchingLeaderboard",
  data() {
    return {
      loading: false,
      filter: {
        cse: true,
        pitchingsubjective: true,
        pitchvelocity: true,
        obj_spin_efficiency_max_median: true,
        verticalbreak: true,
        horizontalbreak: true
      },
      tooltipShow: false,
      isMqEvent: false,
      isBaseball: false
    };
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },

    showCse: Boolean,
    showPitchingSubjective: Boolean,
    showPitchVelocity: Boolean,
    showSpinRate: Boolean,
    showVerticalBreak: Boolean,
    showHorizontalBreak: Boolean
  },
  computed: {
    getSelection() {
      return this.$store.getters["score/getSelected"];
    },
    getActiveCol() {
      return this.$store.getters["events/activeColName"];
    },
    getVersion() {
      return this.$store.getters["events/getSlots"];
    }
  },
  methods: {
    emit(data, object, index) {
      console.log(index);

      let value = parseFloat(data);
      if (["obj_footspeed_h11", "obj_poptime_throw2"].includes(object)) {
        value = 0;
      }
      this.$store.commit("score/updateSelection", {
        index: index.index,
        id: index.id,
        colName: object
      });

      this.$store.commit("score/updateScore", value);
    },
    highlightColumn(colName) {
      this.$emit("highlight-col", colName);
    },
    activeColClickHandler(colName) {
      this.$store.commit("events/updateColName", colName);
    },
    toggleTooltip: function() {
      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "right"
        });
      }
    },
    showTooltip(flag) {
      switch (flag) {
        case 1:
          document.getElementById("tooltip1").classList.remove("opacity-0");
          break;
      }
    },
    hideTooltip(flag) {
      switch (flag) {
        case 1:
          document.getElementById("tooltip1").classList.add("opacity-0");
          break;
      }
    }
  },
  watch: {
    showCse: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          cse: newVal
        };
      }
    },
    showPitchingSubjective: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          pitchingsubjective: newVal
        };
      }
    },
    showPitchVelocity: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          overhandvelocity: newVal
        };
      }
    },
    showSpinRate: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          obj_spin_efficiency_max_median: newVal
        };
      }
    },
    showVerticalBreak: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          verticalbreak: newVal
        };
      }
    },
    showHorizontalBreak: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          horizontalbreak: newVal
        };
      }
    }
  },
  mounted() {
    this.isMqEvent = this.$route.path.match(/mq-events/);
    this.isBaseball = this.$route.path.match(/Baseball/);
    this.isBaseball = this.$route.path.match(/baseball/);
  }
};
</script>

<style lang="css" scoped>
.player {
  margin-bottom: 1rem;
}

.col-cse {
  background: red;
  margin-bottom: 1rem;
}

.selectedRow {
  color: #d9ab28;
  font-weight: bold;
}

.selectFullRow {
  background-color: #0b3253;
  box-shadow: 1px 3px #f3f3f3;
}
</style>
