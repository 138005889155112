<template>
  <div>
    <Nav />
    <div>
      <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
        <div class="text-center">
          <h1
            class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
          >
            <span class="block">Find your Must Qualify Leaderboard</span>
          </h1>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            CSE participants can track their performance through the certified
            CSE Leaderboards. Each CSE evaluation will rank players according to
            sensor data metrics and overall CSE Scores. Find your event below
            and track your ranking!
          </p>
        </div>
      </div>
    </div>
    <div class="lg:mx-40">
      <div class="border-b border-gray-200 sticky top-0">
        <nav class="-mb-px flex tab-navigation" aria-label="Tabs">
          <router-link
            :to="`/mq-events/hitting`"
            class="border-transparent lg:text-2xl md:text-2xl sm:text-lg font-bebas font-bold text-theme-1 hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2"
          >
            Hitting
          </router-link>
          <router-link
            :to="`/mq-events/pitching`"
            class="border-transparent text-theme-1 lg:text-2xl md:text-2xl sm:text-lg font-bebas font-bold hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2"
          >
            Pitching
          </router-link>
          <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
          <router-link
            :to="`/mq-events/defense`"
            class="border-transparent text-theme-1 hover:text-gray-700 lg:text-2xl md:text-2xl sm:text-lg font-bebas font-bold hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2"
            aria-current="page"
          >
            Defensive
          </router-link>
        </nav>
      </div>
    </div>
    <div v-if="loading">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        class="spinner"
      >
        <path d="M21 12a9 9 0 11-6.219-8.56" />
      </svg>
    </div>
    <div v-else>
      <div
        class="lg:mt-16 sm:mt-1 md:mt-3 mx-5 pt-10 grid gap-16 lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 overflow-hidden lg:gap-x-5 lg:gap-y-12"
      >
        <router-link
          v-for="liveleaderboard in events"
          :key="liveleaderboard.cse_eventid"
          class="flex flex-col rounded-lg shadow-sm overflow-hidden"
          :to="
            `/mq-events/${liveleaderboard.sport}/${liveleaderboard.cse_eventid}/hitting`
          "
        >
          <div
            class="flex-1 cursor-pointer bg-white p-6 flex flex-col justify-between"
          >
            <div class="flex-1">
              <p class="text-sm font-medium text-theme-1">
                <a class="hover:underline">
                  {{ liveleaderboard.date }}
                </a>
              </p>
              <a class="block mt-2">
                <p class="text-xl font-semibold text-gray-900">
                  {{ liveleaderboard.leaderboard_eventname }}
                </p>
              </a>
            </div>
            <div class="mt-6 flex items-center">
              <div class="flex-shrink-0">
                <a>
                  <span class="sr-only">{{ liveleaderboard.venue }}</span>
                </a>
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-gray-900">
                  <a class="hover:underline">
                    {{ liveleaderboard.venue }}
                  </a>
                </p>
                <div class="flex space-x-1 text-sm text-gray-500">
                  <time datetime="2020-03-10">
                    {{ liveleaderboard.eventtype }}
                  </time>
                  <span aria-hidden="true"> &middot; </span>
                  <span>
                    {{ liveleaderboard.sport }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";

export default {
  name: "EventsPage",
  components: { Nav, Footer },
  data() {
    return {
      loading: false,
      events: {}
    };
  },
  methods: {
    async getEvents() {
      try {
        const response = await axios({
          method: "GET",
          url:
            "https://cse-api.herokuapp.com/liveleaderboardapi/fastpitch/mq/hitting"
        });

        this.events = response.data.liveleaderboard;
      } catch (error) {
        console.log(error);
      }
    },
    clickMethod() {
      // this.$store.commit("events/updateEventId", id);
      // this.$store.commit("events/updateEventSport", sport);
      const event = this.$store.getters["events/getEventId"];
      console.log(event);

      // this.$router.push(`/events/${sport}/${id}/hitting`);
    }
  },
  created() {
    this.getEvents();
  }
};
</script>

<style scoped>
.global-filter-something-unique {
  display: none;
}
</style>
