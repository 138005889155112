<template>
  <div id="app">
    <metainfo>
      <template v-slot:title="{ content }">{{ content }}</template>
    </metainfo>
    <router-view />
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useMeta, useActiveMeta } from "vue-meta";
export default {
  name: "App",
  data() {
    return {
      metaObj: Object
    };
  },
  mounted() {
    const path = this.$route.path;
    const { meta } = useMeta({
      title: "",
      og: {
        title: ""
      }
    });
    watchEffect(() => {
      meta.title = "College Sports Evaluation | Certified Leaderboards";
    });
    this.metaObj = meta;
  },
  watch: {
    $route(to, from) {
      const path = this.$route.path;
      const title = "title will be changed with " + path;
      watchEffect(() => {
        this.metaObj.title = title;
      });
    }
  }
};
</script>

<style></style>
