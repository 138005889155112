<template>
  <div class="mx-auto px-4">
    <Nav />

    <div class=" lg:flex sm:flex sm:object-center">
      <div class="flex-1">
        <div>
          <h1
            class=" mt-6 font-bold font-bebas whitespace-pre-line leading-7 text-center break-all text-theme-1 lg:text-4xl md:text-4xl sm:text-4xl xs:text-4xl sm:truncate"
          >
            {{ getEventFromState.leaderboard_eventname }}
          </h1>
          <p
            class="m-1 font-bold font-bebas leading-7 text-center text-theme-1 lg:text-4xl md:text-4xl sm:text-4xl xs:text-4xl sm:truncate"
          >
            Leaderboard
          </p>
          <div class="px-10">
            <div class="border-b border-gray-200 sticky top-0">
              <nav class="-mb-px flex tab-navigation" aria-label="Tabs">
                <router-link
                  :to="`/events/objective/${getEventId}/hitting`"
                  class="border-transparent lg:text-2xl md:text-2xl sm:text-lg font-bebas font-bold text-theme-1 hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2"
                >
                  Hitting
                </router-link>
                <router-link
                  v-if="!isBaseball"
                  :to="`/events/objective/${getEventId}/pitching`"
                  class="border-transparent text-theme-1 lg:text-2xl md:text-2xl sm:text-lg font-bebas font-bold hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2"
                >
                  Pitching
                </router-link>
                <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                <router-link
                  :to="`/events/objective/${getEventId}/defense`"
                  class="border-transparent text-theme-1 hover:text-gray-700 lg:text-2xl md:text-2xl sm:text-lg font-bebas font-bold hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2"
                  aria-current="page"
                >
                  Defensive
                </router-link>
              </nav>
            </div>
          </div>
        </div>
        <div v-if="renderState.data">
          <!-- <ShareNetwork
            network="twitter"
            :url="share.url"
            :title="getEventFromState.eventname"
            :description="share.description"
            :quote="share.quote"
            :hashtags="share.hashtags"
            :twitterUser="share.twitterUser"
          >
            <i class="fab fah fa-lg fa-twitter"></i>
            <span>Share on Twitter</span>
          </ShareNetwork>
          <ShareNetwork
            network="facebook"
            :url="share.url"
            :style="{ backgroundColor: network.color }"
            :title="getEventFromState.eventname"
            :description="share.description"
            :quote="share.quote"
            :hashtags="share.hashtags"
          >
            Share on Facebook
          </ShareNetwork>
           -->
          <!-- <i class="fab fah fa-lg fa-facebook-f" />
          <font-awesome-icon icon="facebook" />

          <ShareNetwork
            v-for="network in networks"
            :network="network.network"
            :key="network.network"
            :style="{ backgroundColor: network.color }"
            :url="sharing.url"
            :title="sharing.title"
            :description="sharing.description"
            :quote="sharing.quote"
            :hashtags="sharing.hashtags"
            :twitterUser="sharing.twitterUser"
          >
            <i :class="network.icon"></i>
            <span>{{ network.name }}</span>
          </ShareNetwork> -->
        </div>

        <div v-if="loading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            class="spinner"
          >
            <path d="M21 12a9 9 0 11-6.219-8.56" />
          </svg>
        </div>
        <div v-else>
          <router-view id="slkfjdslkfjl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Nav from "components/Nav";
// import Score from "components/Score";

// import LeaderBoard from "../components/LeaderBoard";

export default {
  name: "EventsPage",
  data() {
    return {
      players: [],
      isBaseball: false,
      loading: false,
      sharing: {
        url: window.location,
        title:
          "Say hi to Vite! A brand new, extremely fast development setup for Vue.",
        description:
          'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        quote: "The hot reload is so fast it's near instant. - Evan You",
        hashtags: "leadersboards,cse",
        twitterUser: "tprimmer"
      },
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "fab fah fa-lg fa-facebook-f",
          color: "#1877f2"
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fah fa-lg fa-twitter",
          color: "#1da1f2"
        }
      ]
    };
  },
  components: {
    Nav
    // LeaderBoard
  },
  computed: {
    renderState() {
      return this.$store.getters["events/getRenderingState"];
    },
    getEventFromState() {
      return this.$store.getters["events/getEvent"];
    },
    getEventId() {
      return this.$store.getters["events/getEventId"];
    },
    getEventName() {
      return this.$store.getters["events/getEventName"];
    }
  },
  methods: {
    async getEvent() {
      const response = await axios({
        method: "GET",
        url: `https://cse-api.herokuapp.com/products/getevent/${this.$route.params.eventId}`
      });

      const event = response.data.event[0];
      this.event = event;
      this.$store.commit("events/updateEvent", event);
    }
  },
  mounted() {
    this.getEvent();
    this.$store.commit("events/updateEventId", this.$route.params.eventId);
    this.isBaseball = this.$route.path.match(/Baseball/);
    this.isBaseball = this.$route.path.match(/baseball/);
  },
  created() {
    // this.getEvents();
  },
  metaInfo() {
    return {
      meta: [
        {
          property: "og:image",
          content: `https://og-image.vercel.app/CSE Fastpitch Chicago Area July 7, 2020.png?images=&images=https://images.unsplash.com/photo-1434854775064-aa2c72a4aa32`
        }
      ]
    };
  }
};
</script>

<style>
.spinner {
  stroke: black;
  animation-name: spinner;
  animation-duration: 0.8s;
  animation-direction: normal;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tab-navigation .router-link-active {
  border-color: #0b3253;
}
</style>
