import { createApp } from "vue";

import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueSocialSharing from "vue-social-sharing";
import { createMetaManager, plugin as metaPlugin } from "vue-meta";

import "./index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUserSecret);

// Vue.config.productionTip = false;

const app = createApp(App)
  .use(router)
  .use(store)
  .use(createMetaManager())
  .use(metaPlugin)
  .use(VueSocialSharing)
  .component("font-awesome-icon", FontAwesomeIcon);

// eslint-disable-next-line promise/catch-or-return
router.isReady().then(() => app.mount("#app"));
