<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <Nav />
  <div class="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
    <div
      class="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl"
    >
      <div>
        <h2
          class="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl sm:tracking-tight"
        >
          National Leaderboards
        </h2>
        <div
          class="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center"
        >
          <p class="text-xl text-gray-500">
            College Sports Evaluation’s National Leaderboards compile the
            results of every CSE participant and rank them categorically
            according to their results. Want to see how your athlete stacks up?
            Select the year you participated and see!
          </p>
        </div>
      </div>
      <div class="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
        <div v-for="post in posts" :key="post.title">
          <p class="text-sm text-gray-500">
            <time :datetime="post.datetime">{{ post.date }}</time>
          </p>
          <a href="#" class="mt-2 block">
            <p class="text-xl font-semibold text-gray-900">
              {{ post.title }}
            </p>
            <p class="mt-3 text-base text-gray-500">
              {{ post.description }}
            </p>
          </a>
          <div class="mt-3">
            <a
              :href="post.href"
              class="text-base font-semibold text-theme-1 hover:text-theme-10"
            >
              View National Leaderboard
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Nav from "components/Nav";
const posts = [
  {
    title: "2023 National Leaderboard",
    href: "/national/fastpitch/2023/hitting",
    description:
      "The 2023 National Leaderboard continuously updates throughout the year. Two weeks after your athlete’s event, her results will be posted.",
    date: "Jan 01, 2023 - Present",
    datetime: "2020-03-16"
  },
  {
    title: "2022 National Leaderboard",
    href: "/national/fastpitch/2022/hitting",
    description:
      "The 2022 National Leaderboard continuously updates throughout the year. Two weeks after your athlete’s event, her results will be posted.",
    date: "Jan 01, 2022 - Dec 31, 2022",
    datetime: "2020-03-16"
  },
  {
    title: "2021 National Leaderboard",
    href: "/national/fastpitch/hitting",
    description:
      "The 2021 National Leaderboard is a comprehensive list of all CSE participants who completed a CSE evaluation. All results are final.",
    date: "Jan 01, 2021 - Dec 31, 2021",
    datetime: "2020-03-10"
  }
];
</script>
