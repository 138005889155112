<template>
  <div>
    <div class="mx-auto px-4">
      <!-- <VueSocialSharing /> -->
      <div>
        <div>
          <div class="flex flex-wrap overflow-hidden xl:-mx-1 p-4">
            <div
              class="w-full overflow-hidden xl:my-1 xl:px-1 xl:w-1/4 md:w-1/4 sm:w-1/4"
            >
              <!-- Column Content -->

              <div
                @click="toggleCategoryDropdown = !toggleCategoryDropdown"
                class="flex flex-wrap cursor-pointer my-1 justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
              >
                Select A Category
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="bg-white text-base z-50 float-left py-2 px-4 list-none text-left w-full rounded shadow-lg m-1"
                v-if="toggleCategoryDropdown"
              >
                <div class="flex flex-col items-left justify-left">
                  <div v-if="!this.isMqEvent && !isBaseball">
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.category.cse"
                        type="checkbox"
                        id="CSE overall"
                        name="CSE overall"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-theme-1">CSE Overall</span>
                    </label>
                  </div>
                  <div v-if="getVersion === 1 && !isBaseball">
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.category.hitting"
                        type="checkbox"
                        id="hitting"
                        name="hitting"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-theme-1">Power Hitting</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.category.throw"
                        type="checkbox"
                        id="throw"
                        name="throw"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-theme-1">Bat Speed</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.category.obj_exitvelo_swing_median"
                        type="checkbox"
                        id="obj_exitvelo_swing_median"
                        name="obj_exitvelo_swing_median"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-theme-1"
                        >Median Exit Velocity</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.category.footspeed"
                        type="checkbox"
                        id="footspeed"
                        name="footspeed"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-theme-1">Rot. Acceleration</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="w-full overflow-hidden xl:my-1 xl:px-1 xl:w-1/4 md:w-1/4 sm:w-1/4"
            >
              <!-- Column Content -->
              <div
                @click="toggleFilterDropdown = !toggleFilterDropdown"
                class="flex cursor-pointer flex-wrap justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 my-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
              >
                Filter by Position
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="bg-white text-base z-50 float-left py-2 px-4 list-none text-left w-full rounded shadow-lg m-1"
                v-if="toggleFilterDropdown"
              >
                <div class="flex flex-col items-left justify-left">
                  <div class="flex flex-col">
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.position.Outfield"
                        type="checkbox"
                        value="Outfield"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-theme-1">Outfield</span>
                    </label>

                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.position.Catcher"
                        type="checkbox"
                        value="Catcher"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-gray-700">Catcher</span>
                    </label>
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.position.MiddleInfield"
                        type="checkbox"
                        value="Middle Infield"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-gray-700">Middle Infield</span>
                    </label>
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.position.Corner"
                        type="checkbox"
                        value="Corner"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-gray-700">Corner</span>
                    </label>
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.position.Pitcher"
                        type="checkbox"
                        value="Pitcher"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-gray-700">Pitcher</span>
                    </label>
                    <label
                      class="inline-flex items-left mt-3"
                      v-if="this.isNational"
                    >
                      <input
                        v-model="filters.position.Hitter"
                        type="checkbox"
                        value="Hitter"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-gray-700">Hitter</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="w-full overflow-hidden xl:my-1 xl:px-1 xl:w-1/4 md:w-1/4 sm:w-1/4"
            >
              <!-- Column Content -->
              <div
                @click="toggleGradYearDropdown = !toggleGradYearDropdown"
                class="flex cursor-pointer flex-wrap justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 my-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
              >
                Filter by Grad Year
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div
                class="bg-white text-base z-50 float-left py-2 px-4 list-none text-left w-full rounded shadow-lg m-1"
                v-if="toggleGradYearDropdown"
              >
                <div class="flex flex-col items-left justify-left">
                  <div class="flex flex-col">
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.gradYears['2021']"
                        type="checkbox"
                        value="2021"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-theme-1">2021</span>
                    </label>

                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.gradYears['2022']"
                        type="checkbox"
                        value="2022"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-gray-700">2022</span>
                    </label>
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.gradYears['2023']"
                        type="checkbox"
                        value="2023"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-gray-700">2023</span>
                    </label>
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.gradYears['2024']"
                        type="checkbox"
                        value="2024"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-gray-700">2024</span>
                    </label>
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.gradYears['2025']"
                        type="checkbox"
                        value="2025"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-gray-700">2025</span>
                    </label>
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.gradYears['2026']"
                        type="checkbox"
                        value="2026"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-gray-700">2026</span>
                    </label>
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.gradYears['2027']"
                        type="checkbox"
                        value="2027"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-gray-700">2027</span>
                    </label>
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.gradYears['2028']"
                        type="checkbox"
                        value="2027"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-gray-700">2028</span>
                    </label>
                    <label class="inline-flex items-left mt-3">
                      <input
                        v-model="filters.gradYears['NotSubmitted']"
                        type="checkbox"
                        value="NotSubmitted"
                        class="form-checkbox h-5 w-5 text-theme-1"
                        checked
                      /><span class="ml-2 text-gray-700">Not Submitted</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="w-full overflow-hidden xl:my-1 xl:px-1 xl:w-1/4 md:w-1/4 sm:w-1/4"
            >
              <!-- Column Content -->
              <div class="mt-1 relative rounded-md shadow-sm">
                <input
                  v-model="filters.gpaSearchValue"
                  type="text"
                  name="gpa"
                  id="gpa"
                  class="focus:ring-theme-1 focus:border-theme-1 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Input GPA"
                  @keypress="onlyNumber"
                />
                <div class="absolute inset-y-0 right-0 flex items-center">
                  <label for="filter" class="sr-only">Filter By</label>
                  <select
                    v-model="filters.gpaSearchType"
                    id="filter"
                    name="filter"
                    class="cursor-pointer focus:ring-theme-1 focus:border-theme-1 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                  >
                    <option value="0">No Condition</option>
                    <option value="1">Greater Than</option>
                    <option value="2">Less Than</option>
                    <option value="3">Equal To</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <leader-board
        :data="this.filteredPlayers"
        @click="scoreClick"
        :category="filters.category"
        :position="filters.position"
        @highlight-col="highlightColumn"
        :loading="colDataFetchingLoading"
      />
    </div>
    <div v-if="positionFilterErrorMessage">Please select a position.</div>
  </div>
</template>

<script>
// import VueSocialSharing from "vue-social-sharing";
import Popper from "popper.js";
import axios from "axios";
import LeaderBoard from "@/components/LeaderBoards/HittingLeaderBoard";
import { useMeta } from "vue-meta";
export default {
  name: "Hitting",
  data() {
    return {
      eventId: "",
      isBaseball: false,
      toggleFilterDropdown: false,
      toggleGradYearDropdown: false,
      toggleCategoryDropdown: false,
      initFilters: true,
      players: [],
      version: "",
      colDataFetchingLoading: false,
      colDataFetchingError: false,
      showFilter: true,
      selectedScore: 0,
      positionFilter: [
        "Outfield",
        "Catcher",
        "Middle Infield",
        "Corner",
        "Pitcher",
        "Hitter"
      ],
      positionFilterErrorMessage: false,
      dropdownPopoverShow: false,

      pageNum: 1,
      highlightColName: "",
      filterRoute: [
        "hitting",
        "cse",
        "footspeed",
        "throw",
        "obj_exitvelo_swing_median"
      ],
      filters: {
        filter: this.$route.query.filter || "",
        showCategory: true,
        category: {
          cse: true,
          throw: true,
          footspeed: true,
          hitting: true,
          obj_exitvelo_swing_median: true
        },
        position: {
          Outfield: true,
          Catcher: true,
          MiddleInfield: true,
          Corner: true,
          Pitcher: true,
          Hitter: Boolean(this.$route.path.match(/national/))
        },
        gradYears: {
          "2021": true,
          "2022": true,
          "2023": true,
          "2024": true,
          "2025": true,
          "2026": true,
          "2027": true,
          "2028": true,
          NotSubmitted: true
        },
        gpaSearchValue: "",
        gpaSearchType: 0
      },
      sortCol: {
        name: this.isMqEvent
          ? "bat_speed"
          : "subj_collegefit_overall_scoreweighted_conv2080",
        order: "desc"
      },
      metaObj: Object
    };
  },
  components: { LeaderBoard },
  computed: {
    getEventId() {
      return this.$store.getters["events/getEventId"];
    },
    isNational() {
      return this.$route.path.match(/national/);
    },
    isMqEvent() {
      return this.$route.path.match(/mq-events/);
    },
    getVersion() {
      return this.$store.getters["events/getSlots"];
    },
    isDataExists() {
      return this.$store.getters["events/getRenderingState"].data;
    },
    getActiveColName() {
      return this.$store.getters["events/activeColName"];
    },
    getFilters(filter) {
      return this.filterRoute.includes(filter);
    },
    filteredPlayers() {
      const type = parseInt(this.filters.gpaSearchType);
      const value = parseFloat(this.filters.gpaSearchValue) || 0;
      const filteredByGpa = this.players.filter(player => {
        const gpa = parseFloat(player.gpa) || 0;
        if (type === 0) return true;
        else if (type === 1) return gpa > value;
        else if (type === 2) return gpa < value;
        else return gpa === value;
      });
      const gradYearValues = Object.keys(this.filters.gradYears).filter(key => {
        return this.filters.gradYears[key];
      });
      const filteredByGradYear = filteredByGpa.filter(player => {
        return (
          gradYearValues.indexOf(String(player.gradyear).replace(" ", "")) > -1
        );
      });
      const positionValues = Object.keys(this.filters.position).filter(key => {
        return this.filters.position[key];
      });
      return filteredByGradYear.filter(player => {
        return (
          positionValues.indexOf(String(player.sport).replace(" ", "")) > -1
        );
      });
    }
  },
  methods: {
    getEventData: async function() {
      if (this.$route.params.eventId) {
        try {
          const url = `https://cse-api.herokuapp.com/products/getevent/${this.$route.params.eventId}`;

          const response = await axios({
            method: "GET",
            url: url
          });
          this.eventId = response.data.event[0].cse_eventid;
          this.metaObj.title = response.data.event[0].eventname;

          this.metaObj.og.title = response.data.event[0].eventname;

          this.getEvents();
        } catch (error) {
          console.log(error);
        }
      } else {
        this.getEvents();
      }
    },
    async getEvents() {
      this.$store.commit("events/updateRendering", {
        loading: true
      });

      this.colDataFetchingLoading = true;
      this.loading = true;
      try {
        this.players = [];

        const postionParams = [];

        for (const key in this.filters.position) {
          if (this.filters.position[key]) {
            postionParams.push(key);
          }
        }
        let url = `https://cse-api.herokuapp.com/leaderboard/${this.$route.params.sports.toLowerCase()}/hitting${
          this.eventId ? "/" + this.eventId : ""
        }`;
        if (this.isMqEvent)
          url = `https://cse-api.herokuapp.com/liveleaderboardapi/${this.$route.params.sports.toLowerCase()}/mq/hitting/${
            this.eventId
          }`;
        const players = await axios({
          method: "GET",
          url: url,
          params: {
            tg_position: this.positionFilter.join(","),
            sort: `${this.sortCol.name}:${this.sortCol.order}`
          }
        });
        const data = this.isMqEvent
          ? players.data.liveleaderboard
          : players.data.leaderboard;
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          const item = {
            id: element.cse_playerid,
            name: element.first_name,
            lastName: element.last_name,
            gpa: element.gpa,
            gradyear: element.graduation_year,
            cseScore: element.subj_collegefit_overall_scoreweighted_conv2080,
            powerHitting: element.subj_collegefit_offensepower_scoreconv2080,
            rotAcceleration: element.rotational_acceleration,
            batSpeed: element.bat_speed,
            sport: element.tg_position,
            obj_exitvelo_swing_median: element.obj_exitvelo_swing_median
          };
          if (this.eventId) item.eventName = element.cse_eventid.eventname;
          this.players.push(item);
        }

        this.$store.commit("events/updateRendering", {
          data: true
        });
      } catch (error) {
        this.$store.commit("events/updateRendering", {
          error: true
        });
      } finally {
        this.loading = false;
        this.initFilters = false;
        this.$store.commit("events/updateRendering", {
          loading: false
        });
      }
    },
    loadMore() {
      // this.pageNum++
      this.pageNum = this.pageNum + 1;
      this.getEvents();
    },
    scoreClick(event) {
      const score = parseFloat(event);
      // this.selectedScore = (3 / 5) * score + 20;
      this.selectedScore = score;
    },
    updateFilter() {
      const categoryFilter = this.$route.query.category?.split(",");
      const positionFilter = this.$route.query.position?.split(",");
      const gradYearsFilter = this.$route.query.gradYears?.split(",");

      const categoryObj = this.filters.category;

      for (const key in categoryObj) {
        this.filters.category[key] = false;
        if (categoryFilter.includes(key)) {
          this.filters.category[key] = true;
        }
      }

      const gradYearsObj = this.filters.gradYears;
      for (const key in gradYearsObj) {
        this.filters.gradYears[key] = false;
        if (gradYearsFilter.includes(key)) {
          this.filters.gradYears[key] = true;
        }
      }

      // position filter
      const positionObj = this.filters.position;
      for (const key in positionObj) {
        this.filters.position[key] = false;
        if (positionFilter.includes(key)) {
          this.filters.position[key] = true;
        }
      }

      this.filters.gpaSearchType = this.$route.query.gpaSearchType;
      this.filters.gpaSearchValue = this.$route.query.gpaSearchValue;
    },
    toggleDropdown: function() {
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        new Popper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start"
        });
      }
    },

    togglePositionDropdown: function() {
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        new Popper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start"
        });
      }
    },

    highlightColumn(e) {
      this.highlightColName = e;

      for (let i = 0; i < this.players.length; i++) {
        // const item = this.players[i];
      }
    },
    onlyNumber($event) {
      // console.log($event.keyCode); //keyCodes value
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    updateRouteURL() {
      const category = this.filters.category;
      const categoryFilter = Object.keys(category)
        .filter(id => {
          if (this.isMqEvent && id === "cse") return false;
          return category[id];
        })
        .join(",");

      const position = this.filters.position;
      const positionFilter = Object.keys(position)
        .filter(id => {
          return position[id];
        })
        .join(",");

      const gradYears = this.filters.gradYears;
      const gradYearsFilter = Object.keys(gradYears)
        .filter(id => {
          return gradYears[id];
        })
        .join(",");
      const query = {
        category: categoryFilter,
        gradYears: gradYearsFilter,
        position: positionFilter
      };
      if (this.filters.gpaSearchType) {
        query.gpaSearchType = this.filters.gpaSearchType;
      }
      if (this.filters.gpaSearchValue) {
        query.gpaSearchValue = this.filters.gpaSearchValue;
      }
      this.$router.push({
        path: this.$route.path,
        query: query
      });
    }
  },
  watch: {
    getActiveColName: {
      handler(newVal) {
        this.sortCol.name = newVal;
        if (!this.initFilters) this.getEvents();
      }
    },
    "filters.category": {
      handler(newVal) {
        this.updateRouteURL();
      },
      deep: true
    },
    "filters.gradYears": {
      handler(newVal) {
        this.updateRouteURL();
      },
      deep: true
    },
    "filters.position": {
      handler(newVal) {
        // if (!this.initFilters) this.getEvents();
        this.updateRouteURL();
      },
      deep: true
    },
    "filters.gpaSearchType": {
      handler(newVal) {
        this.updateRouteURL();
      }
    },
    "filters.gpaSearchValue": {
      handler(newVal) {
        this.updateRouteURL();
      }
    }
  },
  mounted() {
    // mutation for the selected col in vuex
    this.$store.commit(
      "events/updateColName",
      this.isMqEvent
        ? "bat_speed"
        : "subj_collegefit_overall_scoreweighted_conv2080"
    );
    const { meta } = useMeta({
      title: "Hitting",
      og: {
        title: "Hitting"
      }
    });
    this.metaObj = meta;
    this.isBaseball = this.$route.path.match(/Baseball/);
    this.isBaseball = this.$route.path.match(/baseball/);
  },
  created() {
    if (
      this.$route.query.category ||
      this.$route.query.position ||
      this.$route.query.gradYears ||
      this.$route.query.gpaSearchType ||
      this.$route.query.gpaSearchValue
    ) {
      this.updateFilter();
    }
    this.getEventData();
  }
};
</script>

<style lang="css">
div.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  padding-top: 20px;
}

.checkbox-input {
  display: none;
}

.checkbox {
  border: 1px solid red;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}
</style>
