<template>
  <div>
    <Nav />
    <div>
      <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
        <div class="text-center">
          <h1
            class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
          >
            <span class="block">Find your Full CSE Leaderboard</span>
          </h1>
          <p
            class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
          >
            CSE participants can track their performance through the certified
            CSE Leaderboards. Each CSE evaluation will rank players according to
            sensor data metrics and overall CSE Scores. Find your event below
            and track your ranking!
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="flex flex-wrap -mx-2 overflow-hidden lg:-mx-1 xl:-mx-1">
      <div
        class="my-2 px-2 w-full overflow-hidden lg:my-1 lg:px-1 lg:w-1/3 xl:my-1 xl:px-1 xl:w-1/3"
      > -->
    <!-- Column Content -->
    <!-- <div class="flex-1 flex justify-center lg:justify-end">
          <div class="w-full px-2 lg:px-6">
            <label for="search" class="sr-only">Search Events</label>
            <div class="relative text-indigo-200 focus-within:text-gray-400">
              <div
                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-Events-none"
              >
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <input
                id="search"
                name="search"
                class="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-300 bg-opacity-25 text-indigo-100 placeholder-indigo-200 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
                placeholder="Search projects"
                type="search"
              />
            </div>
          </div>
        </div> -->
    <!-- </div>

      <div
        class="my-2 px-2 w-full overflow-hidden lg:my-1 lg:px-1 lg:w-1/3 xl:my-1 xl:px-1 xl:w-1/3"
      > -->
    <!-- Column Content -->
    <!-- </div>

      <div
        class="my-2 mb-4 px-2 w-full overflow-hidden lg:my-1 lg:px-1 lg:w-1/3 xl:my-1 xl:px-1 xl:w-1/3"
      > -->
    <!-- Column Content -->
    <!-- </div>
    </div> -->
    <div v-if="loading">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        class="spinner"
      >
        <path d="M21 12a9 9 0 11-6.219-8.56" />
      </svg>
    </div>
    <div v-else>
      <div
        class="lg:mt-16 sm:mt-1 md:mt-3 mx-5 pt-10 grid gap-16 lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 overflow-hidden lg:gap-x-5 lg:gap-y-12"
      >
        <router-link
          v-for="event in events"
          :key="event.cse_eventid"
          class="flex flex-col rounded-lg shadow-sm overflow-hidden"
          :to="`/events/${event.sport}/${event.cse_eventid}/hitting`"
        >
          <!-- <div class="flex-shrink-0">
              <img
                class="h-48 w-full object-cover"
                src="@/assets/joshua-peacock-aMuXhFkbxEw-unsplash.jpg"
                alt=""
              />
            </div> -->
          <div
            class="flex-1 cursor-pointer bg-white p-6 flex flex-col justify-between"
          >
            <div class="flex-1">
              <p class="text-sm font-medium text-theme-1">
                <a class="hover:underline">
                  {{ event.date }}
                </a>
              </p>
              <a class="block mt-2">
                <p class="text-xl font-semibold text-gray-900">
                  {{ event.leaderboard_eventname }}
                </p>
                <!-- <p class="mt-3 text-base text-gray-500">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Velit facilis asperiores porro quaerat doloribus, eveniet
                    dolore. Adipisci tempora aut inventore optio animi., tempore
                    temporibus quo laudantium.
                  </p> -->
              </a>
            </div>
            <div class="mt-6 flex items-center">
              <div class="flex-shrink-0">
                <a>
                  <span class="sr-only">{{ event.venue }}</span>
                  <!-- <img
                    class="h-10 w-10 rounded-full"
                    src="@/assets/joshua-peacock-aMuXhFkbxEw-unsplash.jpg"
                    alt=""
                  /> -->
                </a>
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-gray-900">
                  <a class="hover:underline">
                    {{ event.venue }}
                  </a>
                </p>
                <div class="flex space-x-1 text-sm text-gray-500">
                  <time datetime="2020-03-10">
                    {{ event.eventtype }}
                  </time>
                  <span aria-hidden="true"> &middot; </span>
                  <span>
                    {{ event.sport }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";

export default {
  name: "EventsPage",
  components: { Nav, Footer },
  data() {
    return {
      loading: false,
      events: {}
    };
  },
  methods: {
    async getEvents() {
      try {
        const response = await axios({
          method: "GET",
          url: "https://cse-api.herokuapp.com/products/pastevents/fastpitch"
        });
        this.events = response.data.event;
      } catch (error) {
        console.log(error);
      }
    },
    clickMethod() {
      // this.$store.commit("events/updateEventId", id);
      // this.$store.commit("events/updateEventSport", sport);
      const event = this.$store.getters["events/getEventId"];
      console.log(event);

      // this.$router.push(`/events/${sport}/${id}/hitting`);
    }
  },
  created() {
    this.getEvents();
  }
};
</script>

<style scoped>
.global-filter-something-unique {
  display: none;
}
</style>
