const state = {
  eventId: "",
  colName: "",
  eventName: "",
  events: {},
  sport: "",
  slots: "",

  rendering: {
    loading: true,
    error: false,
    data: false
  }
};

const getters = {
  getEvent: state => state.events,
  getSlots: state => state.slots,
  getEventId: state => state.eventId,
  getEventName: state => state.eventName,
  getSportName: state => state.sport,
  activeColName: state => state.colName,
  getRenderingState: state => state.rendering
};

const mutations = {
  updateEvent(state, payload) {
    state.events = payload;
  },
  updateEventId(state, payload) {
    state.eventId = payload;
  },
  updateSlots(state, payload) {
    state.slots = payload;
  },
  updateEventName(state, payload) {
    state.eventName = payload;
  },
  updateEventSport(state, payload) {
    state.sport = payload;
  },
  updateColName(state, payload) {
    state.colName = payload;
  },
  updateRendering(state, payload) {
    state.rendering = {
      ...state.rendering,
      ...payload
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
