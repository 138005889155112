<template>
  <div>
    <div
      class="my-2 p-3 sm:-mx-6 lg:-mx-8 md:-mx-8 bg-white overflow-x-auto z-10"
    >
      <div
        class="py-2 md:px-52 align-middle inline-block min-w-full sm:px-6 lg:px-8"
      >
        <div class="border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full shadow-md divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 bg-gray-50 text-left text-xs table-fixed font-medium text-gray-500 uppercase tracking-wider"
                >
                  Rank
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 bg-gray-50 text-left text-xs table-fixed font-medium text-gray-500 uppercase tracking-wider"
                >
                  Player Name
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold': getActiveCol === 'bat_speed'
                  }"
                  v-if="category.throw"
                  scope="col"
                  class="px-6 cursor-pointer py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('bat_speed')"
                >
                  Bat Speed
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold':
                      getActiveCol === 'obj_exitvelo_swing_median'
                  }"
                  v-if="category.obj_exitvelo_swing_median"
                  scope="col"
                  class="px-6 cursor-pointer py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('obj_exitvelo_swing_median')"
                >
                  Median Exit Velocity
                </th>
                <th
                  :class="{
                    'text-theme-1 font-bold':
                      getActiveCol === 'rotational_acceleration'
                  }"
                  v-if="category.footspeed"
                  scope="col"
                  class="px-6 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="activeColClickHandler('rotational_acceleration')"
                >
                  Rot. Acceleration
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(player, index) in data"
                :key="player.cse_playerid"
                class="player bg-white border-b shadow-sm border-gray-200 sm:rounded-lgshaddow min-w-full divide-y divide-gray-200 overflow-x-20"
                :class="{
                  selectFullRow: getSelection.id === player.id
                }"
              >
                <td
                  class="px-6 py-4 bg-white whitespace-nowrap text-sm font-medium text-gray-900"
                  :class="{
                    'text-theme-40 bg-theme-1 shadow-md':
                      getSelection.id === player.id
                  }"
                >
                  {{ index + 1 }}
                </td>
                <td
                  :class="{
                    'text-theme-40 bg-theme-1': getSelection.id === player.id
                  }"
                  class="lg:px-6 pr-2 pl-1 py-4 bg-white z-49 whitespace-nowrap text-sm text-gray-500"
                >
                  <p
                    :class="{
                      'text-theme-40 bg-theme-1': getSelection.id === player.id
                    }"
                    class="text-md font-medium text-theme-1"
                  >
                    {{ player.name }} {{ player.lastName }}
                  </p>
                  <p
                    :class="{
                      'text-theme-40 bg-theme-1': getSelection.id === player.id
                    }"
                    class="text-sm text-gray-500"
                  >
                    {{ player.sport }} <br />
                    GPA: {{ player.gpa }} <br />
                    Graduation Year:
                    {{ player.gradyear }}
                  </p>
                </td>
                <td
                  v-if="category.throw"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <div
                    v-if="player.batSpeed > 0"
                    class="flex"
                    style="align-items: center"
                  >
                    {{ player.batSpeed }} mph
                    <svg
                      style="margin-left: 10px"
                      class="w-4 h-4 flex"
                      v-if="getSelection.id === player.id"
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 385 378"
                    >
                      <path
                        fill="#9e9e9e"
                        class="cls-1"
                        d="M245.61 253a15.29 15.29 0 11-8.72 13.83 15.72 15.72 0 011.17-5.94 3.49 3.49 0 00-.88-3.7l-32.77-33a2.61 2.61 0 00-3-.65 15.32 15.32 0 01-6.72 1.48 15 15 0 01-6.33-1.4 2.58 2.58 0 00-3.15.59l-33.34 33.19a2.89 2.89 0 00-.69 3.38 15.08 15.08 0 011.27 6.07 15.27 15.27 0 11-15.29-15.25 14.35 14.35 0 016.25 1.34 2.69 2.69 0 003.31-.63l33-33a3.13 3.13 0 00.94-3.61 14.52 14.52 0 01-1.24-5.92 15.15 15.15 0 019.74-14.2 2.58 2.58 0 001.91-2.88v-23.64c0-1.43-.21-2.27-1.75-2.84a15.23 15.23 0 01-8.84-8.72 2.53 2.53 0 00-2.89-2h-23.38a2.61 2.61 0 00-2.88 1.81 15.27 15.27 0 110-10.88 2.76 2.76 0 002.81 1.87h23.44c1.42 0 2.23-.22 2.87-1.86a15.13 15.13 0 018.73-8.74c1.68-.58 1.9-1.51 1.91-2.87v-23.64c0-1.39-.23-2.38-1.75-2.84a15.28 15.28 0 1110.82-.06c-1.79.62-1.87 1.57-1.87 2.83v23.67c0 1.25.08 2.27 1.82 2.87a15.23 15.23 0 018.82 8.76 2.49 2.49 0 002.82 1.91h23.42a2.62 2.62 0 002.84-1.78 15.26 15.26 0 11.06 10.89 2.62 2.62 0 00-2.85-1.91h-23.39c-1.36-.05-2.44.27-2.89 1.81a15 15 0 01-8.8 8.82 2.49 2.49 0 00-1.87 2.83v23.57c0 1.28.19 2.37 1.77 2.85a15.19 15.19 0 019.89 14.27 14.2 14.2 0 01-1.14 5.78 3.22 3.22 0 00.8 3.59l32.66 33.16a2.78 2.78 0 003.36.79z"
                      />
                      <path
                        fill="#9e9e9e"
                        class="cls-1"
                        d="M13.4 190.35A181.57 181.57 0 10195 8.78 181.57 181.57 0 0013.4 190.35zM194.63 42a148.28 148.28 0 010 296.55A148.28 148.28 0 01192.19 42z"
                      />
                    </svg>
                  </div>
                </td>
                <td
                  v-if="category.obj_exitvelo_swing_median"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <div
                    v-if="player.obj_exitvelo_swing_median > 0"
                    class="flex"
                    style="align-items: center"
                  >
                    {{ player.obj_exitvelo_swing_median }} mph
                    <svg
                      style="margin-left: 10px"
                      class="w-4 h-4 flex"
                      v-if="getSelection.id === player.id"
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 385 378"
                    >
                      <path
                        fill="#9e9e9e"
                        class="cls-1"
                        d="M245.61 253a15.29 15.29 0 11-8.72 13.83 15.72 15.72 0 011.17-5.94 3.49 3.49 0 00-.88-3.7l-32.77-33a2.61 2.61 0 00-3-.65 15.32 15.32 0 01-6.72 1.48 15 15 0 01-6.33-1.4 2.58 2.58 0 00-3.15.59l-33.34 33.19a2.89 2.89 0 00-.69 3.38 15.08 15.08 0 011.27 6.07 15.27 15.27 0 11-15.29-15.25 14.35 14.35 0 016.25 1.34 2.69 2.69 0 003.31-.63l33-33a3.13 3.13 0 00.94-3.61 14.52 14.52 0 01-1.24-5.92 15.15 15.15 0 019.74-14.2 2.58 2.58 0 001.91-2.88v-23.64c0-1.43-.21-2.27-1.75-2.84a15.23 15.23 0 01-8.84-8.72 2.53 2.53 0 00-2.89-2h-23.38a2.61 2.61 0 00-2.88 1.81 15.27 15.27 0 110-10.88 2.76 2.76 0 002.81 1.87h23.44c1.42 0 2.23-.22 2.87-1.86a15.13 15.13 0 018.73-8.74c1.68-.58 1.9-1.51 1.91-2.87v-23.64c0-1.39-.23-2.38-1.75-2.84a15.28 15.28 0 1110.82-.06c-1.79.62-1.87 1.57-1.87 2.83v23.67c0 1.25.08 2.27 1.82 2.87a15.23 15.23 0 018.82 8.76 2.49 2.49 0 002.82 1.91h23.42a2.62 2.62 0 002.84-1.78 15.26 15.26 0 11.06 10.89 2.62 2.62 0 00-2.85-1.91h-23.39c-1.36-.05-2.44.27-2.89 1.81a15 15 0 01-8.8 8.82 2.49 2.49 0 00-1.87 2.83v23.57c0 1.28.19 2.37 1.77 2.85a15.19 15.19 0 019.89 14.27 14.2 14.2 0 01-1.14 5.78 3.22 3.22 0 00.8 3.59l32.66 33.16a2.78 2.78 0 003.36.79z"
                      />
                      <path
                        fill="#9e9e9e"
                        class="cls-1"
                        d="M13.4 190.35A181.57 181.57 0 10195 8.78 181.57 181.57 0 0013.4 190.35zM194.63 42a148.28 148.28 0 010 296.55A148.28 148.28 0 01192.19 42z"
                      />
                    </svg>
                  </div>
                </td>
                <td
                  v-if="category.footspeed"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <div
                    v-if="player.rotAcceleration > 0"
                    class="flex"
                    style="align-items: center"
                  >
                    {{ player.rotAcceleration }} g
                    <svg
                      style="margin-left: 10px"
                      class="w-4 h-4 flex"
                      v-if="getSelection.id === player.id"
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 385 378"
                    >
                      <path
                        fill="#9e9e9e"
                        class="cls-1"
                        d="M245.61 253a15.29 15.29 0 11-8.72 13.83 15.72 15.72 0 011.17-5.94 3.49 3.49 0 00-.88-3.7l-32.77-33a2.61 2.61 0 00-3-.65 15.32 15.32 0 01-6.72 1.48 15 15 0 01-6.33-1.4 2.58 2.58 0 00-3.15.59l-33.34 33.19a2.89 2.89 0 00-.69 3.38 15.08 15.08 0 011.27 6.07 15.27 15.27 0 11-15.29-15.25 14.35 14.35 0 016.25 1.34 2.69 2.69 0 003.31-.63l33-33a3.13 3.13 0 00.94-3.61 14.52 14.52 0 01-1.24-5.92 15.15 15.15 0 019.74-14.2 2.58 2.58 0 001.91-2.88v-23.64c0-1.43-.21-2.27-1.75-2.84a15.23 15.23 0 01-8.84-8.72 2.53 2.53 0 00-2.89-2h-23.38a2.61 2.61 0 00-2.88 1.81 15.27 15.27 0 110-10.88 2.76 2.76 0 002.81 1.87h23.44c1.42 0 2.23-.22 2.87-1.86a15.13 15.13 0 018.73-8.74c1.68-.58 1.9-1.51 1.91-2.87v-23.64c0-1.39-.23-2.38-1.75-2.84a15.28 15.28 0 1110.82-.06c-1.79.62-1.87 1.57-1.87 2.83v23.67c0 1.25.08 2.27 1.82 2.87a15.23 15.23 0 018.82 8.76 2.49 2.49 0 002.82 1.91h23.42a2.62 2.62 0 002.84-1.78 15.26 15.26 0 11.06 10.89 2.62 2.62 0 00-2.85-1.91h-23.39c-1.36-.05-2.44.27-2.89 1.81a15 15 0 01-8.8 8.82 2.49 2.49 0 00-1.87 2.83v23.57c0 1.28.19 2.37 1.77 2.85a15.19 15.19 0 019.89 14.27 14.2 14.2 0 01-1.14 5.78 3.22 3.22 0 00.8 3.59l32.66 33.16a2.78 2.78 0 003.36.79z"
                      />
                      <path
                        fill="#9e9e9e"
                        class="cls-1"
                        d="M13.4 190.35A181.57 181.57 0 10195 8.78 181.57 181.57 0 0013.4 190.35zM194.63 42a148.28 148.28 0 010 296.55A148.28 148.28 0 01192.19 42z"
                      />
                    </svg>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  name: "LeaderBoard",
  data() {
    return {
      loading: false,
      filter: {
        cse: true,
        hitting: true,
        footspeed: true,
        throw: true
      },
      tooltipShow: false,
      isMqEvent: false,
      isBaseball: false
    };
  },
  // TODO #1 Problem with prob
  props: {
    data: {
      type: Array,
      default: () => []
    },
    category: {
      type: Object,
      required: true
    },
    position: {
      type: Object,
      required: true
    },

    showCse: Boolean,
    showHitting: Boolean,
    showFootspeed: Boolean,
    showThrow: Boolean
  },
  computed: {
    getSelection() {
      return this.$store.getters["score/getSelected"];
    },
    getActiveCol() {
      return this.$store.getters["events/activeColName"];
    }
  },
  methods: {
    emit(data, object, index) {
      console.log(index);

      let value = parseFloat(data);
      if (["obj_footspeed_h11", "obj_poptime_throw2"].includes(object)) {
        value = 0;
      }
      this.$store.commit("score/updateSelection", {
        index: index.index,
        id: index.id,
        colName: object
      });

      this.$store.commit("score/updateScore", value);
    },
    highlightColumn(colName) {
      this.$emit("highlight-col", colName);
    },
    activeColClickHandler(colName) {
      this.$store.commit("events/updateColName", colName);
    },
    toggleTooltip: function() {
      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "right"
        });
      }
    },
    showTooltip(flag) {
      switch (flag) {
        case 1:
          document.getElementById("tooltip1").classList.remove("opacity-0");
          break;
      }
    },
    hideTooltip(flag) {
      switch (flag) {
        case 1:
          document.getElementById("tooltip1").classList.add("opacity-0");
          break;
      }
    }
  },
  watch: {
    category: {
      handler(newVal) {
        console.log(newVal);
      },
      deep: true
    },
    position: {
      handler(newVal) {
        console.log(newVal);
      },
      deep: true
    },
    showCse: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          cse: newVal
        };
      }
    },
    showHitting: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          hitting: newVal
        };
      }
    },
    showFootspeed: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          footspeed: newVal
        };
      }
    },
    showThrow: {
      handler(newVal) {
        this.filter = {
          ...this.filter,
          throw: newVal
        };
      }
    }
  },
  mounted() {
    this.isMqEvent = this.$route.path.match(/mq-events/);
    this.isBaseball = this.$route.path.match(/Baseball/);
    this.isBaseball = this.$route.path.match(/baseball/);
  }
};
</script>

<style lang="css" scoped>
.player {
  margin-bottom: 1rem;
}

.col-cse {
  background: red;
  margin-bottom: 1rem;
}

.selectedRow {
  color: #d9ab28;
  font-weight: bold;
}

.selectFullRow {
  background-color: #0b3253;
  box-shadow: 1px 3px #f3f3f3;
}
</style>
